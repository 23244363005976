import React from "react";
import canteen1 from "../../images/canteen/c1.jpg";
import canteen2 from "../../images/canteen/c2.jpg";
import canteen3 from "../../images/canteen/c3.jpg";


export default function () {
    return (
        <>
            <h1 className="heading heading-top-left-line">Canteen</h1>
            <hr className="my-6" />
            <div className="maindiv1"> {/* Corrected className */}
                <div className="image-container"> {/* Added container for images */}
                    <img src={canteen1} alt="Canteen Image 1" />
                    <img src={canteen2} alt="Canteen Image 2" />
                    <img src={canteen3} alt="Canteen Image 3" />
                </div>
                <p className="p1"> {/* Corrected className */}
                    <i>
                        A college canteen is a vital part of the college environment, serving as a place to eat, socialize, and learn:<br />
                        <b>Food:</b>
                        College canteens provide nutritious, hygienic food to students, staff, and campus residents. They may offer a variety of options, such as vegetarian and vegan choices, salad bars, and healthy snacks. <br />
                        <b>Socialization:</b>
                        Canteens are a place for students to relax, share experiences, and network with others. They can be a hub for academic and extracurricular discussions. <br />
                        <b>Health:</b>
                        Canteens can help students maintain a balanced diet and promote healthy lifestyles. They may offer special nutrition education programs. <br />
                        <b>Convenience:</b>
                        Canteens are a convenient and affordable place to eat, especially for students with long days or who live close to campus. <br />
                        <b>Discounts:</b>
                        Canteens may offer regular discounts to help students with tight budgets.
                    </i>
                    <br />
                </p>
            </div>
        </>
    );
}