import React, { useState } from "react";
import { FiPlus, FiMinus } from "react-icons/fi"; // Import icons from react-icons
export default function AcademicCalendar() {
	const [openIndex, setOpenIndex] = useState(null);
	
		const norms = [
			
			{
				name: "Academic Calendar 2024-25",
				url: "https://gpsakoli.ac.in/public/api/assets/StudentList_PDFs/computer/MSBTE-A.Y.-2024-25-Academic-Calander_Revised_220820241300.pdf",
			},
		];
	
		const togglePDF = (index) => {
			setOpenIndex(openIndex === index ? null : index);
		};
	
	return (
		<>
			<div className="container mx-auto p-4">
			<h1 className="heading heading-top-left-line"> Academic Calendar</h1>
						<ul className="space-y-3">
							{norms.map((norm, index) => (
								<li
									key={index}
									className="p-4 shadow-md "
									style={{
										background: "linear-gradient(160deg, var(--primary-bg-red), var(--card-linear-dark-red))",
									}}
								>
									<button
										onClick={() => togglePDF(index)}
										className="w-full flex justify-between items-center text-lg font-semibold text-white hover:underline focus:outline-none"
									>
										<span> {norm.name}</span>
										{openIndex === index ? <FiMinus size={20} /> : <FiPlus size={20} />}
									</button>
			
									{openIndex === index && (
										<div className="mt-3 border rounded-lg overflow-hidden">
											<iframe
												src={norm.url}
												width="100%"
												height="500px"
												className="border-none"
												title={norm.name}
											></iframe>
										</div>
									)}
								</li>
							))}
						</ul>
					</div>
			
		</>
	);
}
