import React from "react";
import org_struct from "../../images/organization-structure.png";
export default function OrganizationStructure() {
	return (
		<>
					<h1 className="heading heading-top-left-line"> Organization Structure</h1>

			<img src={org_struct} width="100%" height="100%" alt="" />
		</>
	);
}
