import React, { useState } from 'react';
import SocialmediaIcon from './SocialmediaIcon';
import footerBackground from '../images/footer-background.png';
import instagram from '../images/icons/instagram.svg';
import facebook from '../images/icons/facebook.svg';
import linkedin from '../images/icons/linkedin.svg';
import twitter from '../images/icons/twitter.svg';
import Divyanshu from '../images/icons/divyanshu.jpg';
import meet from '../images/icons/meet.jpg';
import ishant from '../images/icons/ishant.jpg';
import sahil from '../images/icons/sahil.jpg';


export default function Footer() {

    const socialMediaIcons = [
        { iconSvg: instagram, iconUrl: 'https://www.instagram.com/govt_polytechnic_sakoli?igsh=MW54bTMxOWd4d2MxZw==' },
        { iconSvg: facebook, iconUrl: 'https://www.facebook.com/profile.php?id=100064051123717' },
        { iconSvg: linkedin, iconUrl: 'https://www.linkedin.com/school/gpsakoli/' },
        { iconSvg: twitter, iconUrl: 'www.twitter.com' },
    ];

    const teamMembers = [
        {
            name: 'Ayush Ramteke',
            profileLink: '',
            resumeLink: '/resumes/ayush_resume.pdf', // Replace with actual paths
            photo: '/images/ayush.jpg', // Replace with actual paths
            gmail: 'ayush.ramteke@example.com',
            contact: '+91-123-456-7890',
            qualification: 'B.Tech (Computer Science)',
            skills: ['Python', 'Java', 'JavaScript', 'React', 'Node.js', 'HTML', 'CSS','Mobile App Development', 'Android', 'iOS', 'React Native'],
            designation: ['Web Developer',', Mobile App Developer'],
        },
        {
            name: 'Divyanshu Dwivedi',
            profileLink: 'https://gpsakoli.ac.in/public/api/assets/NewsLetters_PDFs/computer/DIVYANSHU%20DWIVEDI%20(2).pdf',
            resumeLink: 'https://gpsakoli.ac.in/public/api/assets/NewsLetters_PDFs/computer/DIVYANSHU%20DWIVEDI%20(2).pdf', // Replace with actual paths
            photo: Divyanshu, // Replace with actual paths
            gmail: 'divyanshu2022d@gmail.com',
            contact: '+91 7387632822',
            qualification: 'Diploma in Computer Engineering',
            skills: ['Python', 'Java', 'JavaScript', 'React', 'Node.js', 'HTML', 'CSS','Mobile App Development', 'Android', 'iOS', 'React Native'],
            designation: ['Web Developer',', Mobile App Developer'],
        },
        {
            name: 'Meet Londhe',
            profileLink: 'https://gpsakoli.ac.in/public/api/assets/NewsLetters_PDFs//Resume@2x%20(3).pdf',
            resumeLink: '/resumes/meet_resume.pdf', // Replace with actual paths
            photo: meet, // Replace with actual paths
            gmail: 'londhemeet21@gmail.com',
            contact: '+91 7058553980',
            qualification: 'Diploma in Computer Engineering',
            skills: ['Python', 'Java', 'JavaScript', 'React', 'Node.js', 'HTML', 'CSS','Mobile App Development', 'Android', 'iOS', 'React Native'],
            designation: ['Web Developer',', Mobile App Developer'],
        },
        {
            name: 'Sahil Sopankar',
            profileLink: 'https://gpsakoli.ac.in/public/api/assets/NewsLetters_PDFs/computer/Resume@2x.pdf',
            resumeLink: '/resumes/sahil_resume.pdf', // Replace with actual paths
            photo: sahil, // Replace with actual paths
            gmail: 'sahil.sopankar@example.com',
            contact: '+91 7620821905',
            qualification: 'Diploma in Computer Engineering',
            skills: ['Python', 'Java', 'JavaScript', 'React', 'Node.js', 'HTML', 'CSS','Mobile App Development', 'Android', 'iOS', 'React Native'],
            designation: ['Web Developer',', Mobile App Developer'],
        },
        {
            name: 'Ishant Kshirsagar',
            profileLink: 'https://gpsakoli.ac.in/public/api/assets/NewsLetters_PDFs/computer/Resume@2x%20(1).pdf',
            resumeLink: '/resumes/ishant_resume.pdf', // Replace with actual paths
            photo: ishant, // Replace with actual paths
            gmail: 'ishant0606@gmail.com',
            contact: '+91 9322763960',
            qualification: 'Diploma in Computer Engineering',
            skills: ['Python', 'Java', 'JavaScript', 'React', 'Node.js', 'HTML', 'CSS','Mobile App Development', 'Android', 'iOS', 'React Native'],
            designation: ['Web Developer',', Mobile App Developer'],
        },
    ];


    const [selectedMember, setSelectedMember] = useState(null);

    const showProfile = (member) => {
        setSelectedMember(member);
    };

    const hideProfile = () => {
        setSelectedMember(null);
    };

    return (
        <>
            <div className="footer-container relative mx-auto reveal" style={{ width: '85%' }}>
                <img src={footerBackground} alt="" />
                <div className="inner-footer-container my-20 grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-4 ">
                    <div className="about-college lg:w-80 md:w-56">
                        <h1>About us</h1>
                        <p className='my-1'>Welcome! to GPS <br /> Let's make your future together</p>
                        <div className="social-media-footer-container flex">
                            {socialMediaIcons.map((icon, index) => (
                                <SocialmediaIcon icons={icon} key={index} />
                            ))}
                        </div>
                    </div>
                    <div className="f-quick-links">
                        <h1>Quick Links</h1>
                        <ul>
                            <a href="About.js"><li>About Us</li></a>
                            <a href="About.js"><li>Vision & Mission</li></a>
                            <a href="#"><li>Admission Procedure</li></a>
                            <a href="#"><li>Administrative Staff</li></a>
                        </ul>
                    </div>
                    <div className="f-imp-links">
                        <h1>Important Links</h1>
                        <ul>
                            <a href="#"><li>Mandatory Disclosure</li></a>
                            <a href="#"><li>MOOC</li></a>
                            <a href="#"><li>SWAYAM</li></a>
                            <a href="#"><li>SAKSHAT</li></a>
                            <a href="/admin-login/manage-staff-data">Admin Corner</a>
                        </ul>
                    </div>
                </div>

                <div className="footer-copyright flex justify-center relative">
                    Copyright ©2023 All rights reserved | Government Polytechnic, Sakoli :
                    {teamMembers.map((member, index) => (
                        <React.Fragment key={index}>
                            <a
                                href={member.profileLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="hover:underline relative inline-block"
                                onClick={(e) => {
                                    e.preventDefault();
                                    showProfile(member);
                                }}
                                title={member.name + "'s Profile"}
                            >
                                {member.name}
                            </a>
                            {index < teamMembers.length - 1 ? ' | ' : ''}
                        </React.Fragment>
                    ))}
                </div>

            </div>

           
            {/* Profile Popup (New Design) */}
            {selectedMember && (
  <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50 flex justify-center items-center">
    <div className="bg-white p-8 shadow-md w-100 relative">
      <button onClick={hideProfile} className="absolute top-4 right-4 text-gray-500 hover:text-red-700">
        &times;
      </button>
      <div className=""> {/* Center the image */}
        <img
          src={process.env.PUBLIC_URL + selectedMember.photo}
          alt={selectedMember.name + "'s photo"}
          className="w-32 h-32 rounded-full mb-4 object-cover" // Make image round
        />
      </div>
      <h2 className="text-2xl font-bold mb-2 ">{selectedMember.name}</h2> {/* Center the name */}
      <p className="text-gray-700 mb-2 ">{selectedMember.designation}</p> {/* Center designation */}
      <p className="text-gray-700 mb-2 ">{selectedMember.qualification}</p> {/* Center qualification */}
      <p className="text-gray-700 mb-2 ">{selectedMember.gmail}</p> {/* Center gmail */}
      <p className="text-gray-700 mb-2 ">{selectedMember.contact}</p> {/* Center contact */}
      <p className="text-gray-700 mb-4 ">Skills: {selectedMember.skills.join(', ')}</p> {/* Center skills */}

      {selectedMember.resumeLink && (
        <div className=""> {/* Center the link */}
          <a
            href={selectedMember.resumeLink}
            download
            className="underline text-blue-500 mt-2"
          >
            Download Resume
          </a>
          <br></br>
          <br></br>
        </div>
      )}
    </div>
  </div>
)}

        </>
    );
}