import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Lab from "../../images/cm-lab.jpg"; // Default image for lab
import c1 from "../../images/Dept Laboratries/civil/c1.jpg";
import e1 from "../../images/Dept Laboratries/ee/e1.jpg";
import e2 from "../../images/Dept Laboratries/ee/e2.jpg";
import a1 from "../../images/Dept Laboratries/applied mehanics/a1.jpg";
import me1 from "../../images/Dept Laboratries/me/me2.jpg";
import me2 from "../../images/Dept Laboratries/me/me3.jpg";
import me3 from "../../images/Dept Laboratries/me/me1.jpg";
import s1 from "../../images/Dept Laboratries/Science and humnities/s1.jpg";
import s2 from "../../images/Dept Laboratries/Science and humnities/s2.jpg";
import s3 from "../../images/Dept Laboratries/Science and humnities/s3.jpg";
import w1 from "../../images/Dept Laboratries/workshop/w1.jpg";
import w2 from "../../images/Dept Laboratries/workshop/w2.jpg";
import w3 from "../../images/Dept Laboratries/workshop/w3.jpg";
import GalleryImage1  from "../../images/Dept Laboratries/applied mehanics/a1.jpg";




export default function DeptLaboratory(props) {
  const { branch } = useParams();

  const deptData = {

    
    computer: {
         labs: [
	{ name: " Database Lab ", majorEquipments: "-", cost: "₹ 282417", area: "6m x 8.20 meter " },
	{ name: "Network Lab ", majorEquipments: "", cost: "₹ 576414", area: "6m x 8.20 meter" },
	{ name: " Software Lab ", majorEquipments: "-", cost: "₹ 524892", area: "4.75m x 8.30 meter" },
	{ name: " Project Lab  ", majorEquipments: "-", cost: " ₹ 5064802", area: "4.75m x 8.30 meter" },
  { name: " Programming Lab  ", majorEquipments: "-", cost: " ₹ 5064802", area: "4.75m x 8.30 meter" },
  ],
  gallery: [GalleryImage1, GalleryImage1, GalleryImage1], // Add your images here
    },


    civil: {
        labs: [
	{ name: "Hydrolics Lab", majorEquipments: "-", cost: "In Rupees", area: "8.23m x 13.70 meter" },
	{ name: "Computer Lab", majorEquipments: "-", cost: "In Rupees", area: "8.23m x 6.40 meter" },
	{ name: "Concrete Technology & Geotechnical engineering Lab", majorEquipments: "-", cost: "In Rupees", area: "8.25m x 15.30 meter" },
	{ name: "Mechanics of Structure Lanb", majorEquipments: "-", cost: "In Rupees", area: "8.25m x 11.25 meter" },
  ],
  gallery: [c1], // Add your images here
    },


    mechanical: {
        labs: [
	{ name: "Refrigeneration & Air conditioning Lab", majorEquipments: "-", cost: "In Rupees", area: "8.23m x 12.27 meter" },
	{ name: "FLuid mechanics & Machhinery Lab", majorEquipments: "-", cost: "In Rupees", area: "8.25m x 13.75 meter" },
	{ name: " Automobile Lab & Thermal & Power Engineering", majorEquipments: "-", cost: "In Rupees", area: "8.20m x 15.25 meter" },
	{ name: "Cad Lab", majorEquipments: "-", cost: "In Rupees", area: "8.23m x 6.40 meter" },
	{ name: "metrology & quality control Lab", majorEquipments: "-", cost: "In Rupees", area: "8.25m x 10.70 meter" },
	{ name: " Mechanical engineering material Lab", majorEquipments: "-", cost: "In Rupees", area: "8.25m x 10.70 meter" },
  ],
  gallery: [me1, me2, me3], // Add your images here
    },


    electrical: {
     labs: [
	{ name: "Computer Lab", majorEquipments: "-", cost: "In Rupees", area: "8.20m x 9.27 meter" },
	{ name: "Basic ELectrical & Circuit & Network Lab", majorEquipments: "-", cost: "In Rupees", area: "8.30m x 9.27 meter" },
	{ name: "Measurement & Workshop Lab", majorEquipments: "-", cost: "In Rupees", area: "8.25m x 12.55 meter" },
	{ name: "MODROB & Machine Lab", majorEquipments: "-", cost: "In Rupees", area: "8.25m x 18.35 meter" },
  ],
  gallery: [e1, e2], // Add your images here
    },


    workshop: {
      labs: [
	{ name: "Workshop Lab", majorEquipments: "-", cost: "In Rupees", area: "6m x 8.20 meter" },
  ],
  gallery: [w1, w2, w3], // Add your images here
    },


    applied_mechanics: {
    labs: [
	{ name: "Applied Mechanics Lab ", majorEquipments: "-", cost: "In Rupees", area: "8.23m x 11.25 meter" },
  ],
  gallery: [a1], // Add your images here
    },


    science_humanities: {
      labs: [
	{ name: "Physics Lab", majorEquipments: "-", cost: "In Rupees", area: "8.23m x 18.80 meter" },
	{ name: "Chemistry Lab", majorEquipments: "-", cost: "In Rupees", area: "8.25m x 20.30 meter" },
	{ name: "Language Lab", majorEquipments: "-", cost: "In Rupees", area: "8.25 x 6.50 meter" },
	
  ],
  gallery: [s1, s2, s3], // Add your images here
    },


	electronics: { 
		  labs: [
        { name: "Basic Electronics & Workshop ", majorEquipments: "4", cost: "₹ 282417", area: "8.23m x 8 meter" },
        { name: "Digital Electronics & Micrcontroller Lab ", majorEquipments: "6", cost: "₹ 576414", area: "8.23m x 7.55 meter" },
        { name: "Instriumentaion and Control Lab ", majorEquipments: "5", cost: "₹ 524892", area: "8.23m x 7.55 meter" },
        { name: "Electronics Communication Lab  ", majorEquipments: "7", cost: " ₹ 5064802", area: "8.23m x 10.75 meter" },
        { name: "Analog & Power Electronics Lab", majorEquipments: "4", cost: "₹ 282417", area: "6m x 8.20 meter" },
        { name: "Electronics Software & Project Lab", majorEquipments: "4", cost: "₹ 842915", area: "8.23m  x 6.20 meter" },
        ],
		  gallery: [GalleryImage1, GalleryImage1, GalleryImage1], // Add your images here
			},
  };

  const branchData = deptData[branch];

  if (!branchData) {  // Check if branchData exists
    return (
      <>
        <h1 className="heading heading-top-left-line">Laboratories</h1>
        <p>No laboratory information available for this branch.</p> {/* Display message */}
      </>
    );
  }



  return (
    <>
      <h1 className="heading heading-top-left-line">Laboratories</h1>

      {/* Image Gallery */}
      <div className="image-gallery mt-5" style={{ overflow: "hidden" }}>
        <div className="gallery-container" style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}> {/* flexWrap added, justifyContent for centering */}
          {branchData.gallery.map((image, index) => (
            <div key={index} className="gallery-item" style={{ 
              flex: "1 0 200px", // Flexible sizing, min-width 200px
              margin: "10px", // Consistent margin
              maxWidth: "300px" // Optional max-width
             }}>
              <img
                src={image}
                alt={`Gallery ${index + 1}`}
                style={{ display: "block", width: "100%", height: "auto" }} // Image takes full container width
                onLoad={(e) => {
                  
                }}
              />
            </div>
          ))}
        </div>
      </div>

      <div className="maindiv1">
  <div className="mt-5">
    <table border="1px">
      <thead>
        <tr>
          <th className="text-center">Sr No.</th>
          <th>Name of Laboratories</th>
          <th className="text-center" >Major Equipments</th>
          <th>Approx. cost of one equipment in rupees</th>
          <th>Area of Lab </th>
        </tr>
      </thead>
      <tbody>
        {branchData.labs.map((lab, index) => (
          <tr key={index}>
            <td className="text-center">{index + 1}</td>
            <td>{lab.name}</td>
            <td className="text-center" >{lab.majorEquipments}</td>
            <td>{lab.cost}</td>
            <td>{lab.area}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
</div>
    </>
  );
}




