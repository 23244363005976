import React from "react";
import Table from "../Table";

export default function Gymkhana() {
	
	return (
		<><h1 className="heading"></h1>
			<h1 className="heading heading-top-left-line">Gymkhana</h1>
			<hr className="my-6" />
			<div className="gymkhana-container">
				<div className="my-4">
					<h5>Gymkhana Hall:</h5>
					<p>
						The Gymkhana Hall is equipped with Audio-Visual
						facilities having more than 200 students & faculties
						seating capacity. This facility provides an ideal
						location not only students to attend the lecturers by
						various expert but also covers for various Institutional
						programmes.
					</p>
				</div>
				<div className="my-4">
					<h5>Student Co-operative Store :</h5>
					<p>
					Keeping in mind that students get all required stationary and
				learning material under one roof, at reasonable rates, the
				cooperative store runs within the campus. The store is
				registered unit with cooperative Dept of Government of
				Maharashtra. The material like stationary, journal files,
				calculators, drawing sheets, laboratory manuals etc. are
				available in the store. For Store related enquiry contact : Mr.
				Ajay Dahane Mob: 9604585772.
					</p>
				</div>
				<div className="my-4">
					<h5>Sports Aminities :</h5>
					<p>
						A big playground is available for playing out-door
						games. All sport items for outdoor or indoor games such
						as football, cricket kit, vollyball, carom,badminton,
						table tennis etc can be issued from Sport Incharges. For
						more details students can contact to Mr. D. A.
						Brahmankar, Sr. Lecturer, ETC Dept. Mob:9011018986 The
						institute encourages students to participate in various
						games like IEDSSAA, Inter zonal competition etc.
					</p>
				</div>
				<br></br>	<br></br>
				
					<h1 className="heading heading-top-left-line">Gymkhana Commitee</h1>
					<hr className="my-6" />
				</div>
		</>
	);
}
