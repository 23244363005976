import React, { useState } from "react";
import { FiPlus, FiMinus } from "react-icons/fi";
import { useParams } from "react-router-dom";

export default function DeptTimeTable() {
  const { branch } = useParams();
  const [openIndex, setOpenIndex] = useState(null);

  const togglePDF = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  // 📌 Different Timetables for each branch
  const timetableData = {
    computer: [
      { name: "Odd Sem - 2022-23", url: "https://example.com/computer-sem-1.pdf" },
      { name: "Even Sem - 2022-23", url: "https://example.com/computer-sem-2.pdf" },
      { name: "Odd Sem - 2023-24", url: "https://example.com/computer-sem-3.pdf" },
      { name: "Even Sem - 2023-24", url: "https://example.com/computer-sem-4.pdf" },
      { name: "Odd Sem - 2024-25", url: "https://example.com/computer-sem-5.pdf" },
      { name: "Even Sem - 2024-25", url: "https://gpsakoli.ac.in/public/api/assets/Curriculum_PDFs/cm%20Even%202024-2025.pdf.pdf" },
    ],
    civil: [
		{ name: "Odd Sem - 2022-23", url: "https://example.com/computer-sem-1.pdf" },
		{ name: "Even Sem - 2022-23", url: "https://example.com/computer-sem-2.pdf" },
		{ name: "Odd Sem - 2023-24", url: "https://example.com/computer-sem-3.pdf" },
		{ name: "Even Sem - 2023-24", url: "https://example.com/computer-sem-4.pdf" },
		{ name: "Odd Sem - 2024-25", url: "https://example.com/computer-sem-5.pdf" },
		{ name: "Even Sem - 2024-25", url: "https://example.com/computer-sem-6.pdf" },
    ],
    mechanical: [
		{ name: "Odd Sem - 2022-23", url: "https://example.com/computer-sem-1.pdf" },
		{ name: "Even Sem - 2022-23", url: "https://example.com/computer-sem-2.pdf" },
		{ name: "Odd Sem - 2023-24", url: "https://example.com/computer-sem-3.pdf" },
		{ name: "Even Sem - 2023-24", url: "https://example.com/computer-sem-4.pdf" },
		{ name: "Odd Sem - 2024-25", url: "https://example.com/computer-sem-5.pdf" },
		{ name: "Even Sem - 2024-25", url: "https://example.com/computer-sem-6.pdf" },
    ],
    electrical: [
		{ name: "Odd Sem - 2022-23", url: "https://example.com/computer-sem-1.pdf" },
		{ name: "Even Sem - 2022-23", url: "https://example.com/computer-sem-2.pdf" },
		{ name: "Odd Sem - 2023-24", url: "https://example.com/computer-sem-3.pdf" },
		{ name: "Even Sem - 2023-24", url: "https://example.com/computer-sem-4.pdf" },
		{ name: "Odd Sem - 2024-25", url: "https://example.com/computer-sem-5.pdf" },
		{ name: "Even Sem - 2024-25", url: "https://example.com/computer-sem-6.pdf" },
    ],
    electronics: [
		{ name: "Odd Sem - 2022-23", url: "https://gpsakoli.ac.in/public/api/assets/Curriculum_PDFs/Odd%202022-2023.pdf" },
		{ name: "Even Sem - 2022-23", url: "https://example.com/computer-sem-2.pdf" },
		{ name: "Odd Sem - 2023-24", url: "https://gpsakoli.ac.in/public/api/assets/Curriculum_PDFs/Odd%202023-2024.pdf" },
		{ name: "Even Sem - 2023-24", url: "https://gpsakoli.ac.in/public/api/assets/Curriculum_PDFs/Even%202023-2024.pdf" },
		{ name: "Odd Sem - 2024-25", url: "https://gpsakoli.ac.in/public/api/assets/Curriculum_PDFs/Odd%202024-2025.pdf" },
		{ name: "Even Sem - 2024-25", url: "https://gpsakoli.ac.in/public/api/assets/Curriculum_PDFs/Even%202024-2025.pdf" },
    ],
  };

  const branchTimetable = timetableData[branch];

  return (
    <div >
             <h1 className="heading heading-top-left-line">Timetable</h1>  <br></br>


      {branchTimetable ? (
        // 📌 Display two semesters per row
        [0, 2, 4].map((startIndex) => (
          <div key={startIndex} className="flex flex-wrap -mx-2 mb-4">
            {[0, 1].map((offset) => {
              const index = startIndex + offset;
              if (!branchTimetable[index]) return null;
              return (
                <div key={index} className="w-full md:w-1/2 px-2">
                  <div
                    className="p-4 shadow-md"
                    style={{
                      background: "linear-gradient(160deg, var(--primary-bg-red), var(--card-linear-dark-red))",
                    }}
                  >
                    <button
                      onClick={() => togglePDF(index)}
                      className="w-full flex justify-between items-center text-lg font-semibold text-white hover:underline focus:outline-none"
                    >
                      <span>{branchTimetable[index].name}</span>
                      {openIndex === index ? <FiMinus size={20} /> : <FiPlus size={20} />}
                    </button>

                    {openIndex === index && (
                      <div className="mt-3 border rounded-lg overflow-hidden">
                        <iframe
                          src={branchTimetable[index].url}
                          width="100%"
                          height="500px"
                          className="border-none"
                          title={branchTimetable[index].name}
                        ></iframe>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        ))
      ) : (
        <p><p className="text-red-500 text-2xl font-bold text-center mt-4">
        No TimeTable data available for this branch !!
      </p></p>
      )}
    </div>
  );
}
