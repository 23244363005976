import React, { useState } from 'react';
import { Link } from 'react-router-dom';

export default function SideMenu({ links }) {
    const [showMenu, setShowMenu] = useState(false);

    function handleMenu() {
        setShowMenu(!showMenu);
    }

    return (
        <div>
            <div className={`mobile-side-menu lg:hidden flex`} onClick={handleMenu}>
                <span className="material-symbols-outlined">
                    {showMenu ? 'close' : 'widgets'}
                </span>
            </div>
            <div className={`main-menu relative ${showMenu ? 'show-menu' : ''}`}> {/* Use ternary operator */}
                <div className="inner-main-menu absolute w-100 border-card small-menu"> {/* Add the class */}
                    <h1>
                        Main <span className="heading-red">Menu</span>
                    </h1>
                    <ul>
                        {links.map((link, index) => (
                            <li key={index}> {/* Use <li> for consistent styling */}
                                <Link
                                    to={link.path}
                                    onClick={() => {
                                        handleMenu();
                                    }}
                                >
                                    {link.name}
                                    <span className="hover-line"></span>
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
}