import React from "react";
import {CollapseDropdown} from "../Dropdown";

const rti = "right-to-information/RTI.pdf";
export const RightToInformation = () => {
	const Data = [
		{name: "Right To Information Committee", path: rti, showCD: true},
	];
	
	return (
		<>
			<h1 className="heading heading-top-left-line"> Right To Information</h1>
			
			<div className="mt-5">
      
        <table border="1px">
          <thead>
            <tr>
              <td>Sr No.</td>
              <td>Name of Committee Members</td>
              <td>Designation</td>
			  <td>Contacts</td>
              
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>01</td>
              <td>Mr. S. P. Lambhade</td>
              <td>Appealing Officer </td>
              <td>9423433588</td>
            </tr>
            <tr>
              <td>02</td>
              <td>Mr. L. K. Kothwar</td>
              <td>Information Officer</td>
              <td>7588888838</td>
            </tr>
            <tr>
              <td>03</td>
              <td>	Mr. G. P. Patle </td>
              <td> Asst. Information Officer</td>
              <td>-</td>
            </tr>
          </tbody>
        </table>
      </div>
		</>
	);
};
