import React, { useState, useEffect, Suspense, lazy } from 'react';
import Header from '../components/Header';
import SideMenu from "../components/SideMenu";
import DeptAbout from '../components/department-components/DeptAbout';
import DeptResultAnalysis from '../components/department-components/DeptResultAnalysis';
import DeptTimeTable from '../components/department-components/DeptTimeTable';
import DeptNewsLetter from '../components/department-components/DeptNewsLetter';
import DeptCurriculum from '../components/department-components/DeptCurriculum';
import DeptLaboratory from '../components/department-components/DeptLaboratory';
import { DeptPortfolio } from '../components/department-components/DeptPortfolio';
import DeptIRG from '../components/department-components/DeptIRG';
import DigitalLibrary from '../components/student-corner-components/DigitalLibrary';
import { useParams } from 'react-router-dom';
import DeptEventAnalysis from '../components/department-components/DeptEventAnalysis';
import DeptMous from '../components/department-components/DeptMous';

import image1 from '../images/cm s1.jpg';
import image2 from '../images/cm s2.jpg';
import image3 from '../images/cm s3.jpg';
import image4 from '../images/cm s5.jpg';

import image7 from '../images/Slider/electronics/s4.jpg';
import image8 from '../images/Slider/electronics/s1.jpg';
import image5 from '../images/Slider/electronics/s3.jpg';
import image6 from '../images/Slider/electronics/s5.jpg';

const DeptFaculty = lazy(() => import('../components/department-components/DeptFaculty'));

export default function Department() {
    const [labsData, setLabsData] = useState([]);
    const [mousData, setMousData] = useState([]);
    const [supportingStaffData, setSupportingStaffData] = useState([]);
    const [deptPortfolioData, setDeptPortfolioData] = useState([]);
    const [curriculumData, setCurriculumData] = useState([]);
    const [irg, setIRGData] = useState([]);
    const [timeTable, setTimeTable] = useState([]);
    const [resultAnalysis, setResultAnalysis] = useState([]);
    const [eventAnalysis, setEventAnalysis] = useState([]);
    const deptContent = useParams();

    const departmentImages = {
        computer: [
            { src: image1, caption: 'Expert Lecture On Ai Technologies' },
            { src: image2, caption: 'Certification' },
            { src: image3, caption: 'Industry Visit In Click net Communication Pvt Ltd.' },
            { src: image4, caption: 'Mous Signed By Nsemble Technologies Pvt Ltd. Nagpur' }
        ],
        civil: [
            { src: image1, caption: 'Expert Lecture On Ai Technologies' },
            { src: image2, caption: 'Certification' },
            { src: image3, caption: 'Industry Visit In Click net Communication Pvt Ltd.' },
            { src: image4, caption: 'Mous Signed By Nsemble Technologies Pvt Ltd. Nagpur' }
        ],
        electrical: [
            { src: image1, caption: 'Expert Lecture On Ai Technologies' },
            { src: image2, caption: 'Certification' },
            { src: image3, caption: 'Industry Visit In Click net Communication Pvt Ltd.' },
            { src: image4, caption: 'Mous Signed By Nsemble Technologies Pvt Ltd. Nagpur' }
        ],
        mechanical: [
            { src: image1, caption: 'Expert Lecture On Ai Technologies' },
            { src: image2, caption: 'Certification' },
            { src: image3, caption: 'Industry Visit In Click net Communication Pvt Ltd.' },
            { src: image4, caption: 'Mous Signed By Nsemble Technologies Pvt Ltd. Nagpur' }
        ],
        electronics: [
           { src: image8, caption: '' },
            { src: image5, caption: '' },
            { src: image6, caption: '' },
            { src: image7, caption: '' }
        ],
    };

    const images = departmentImages[deptContent.branch] || departmentImages['electronics'];
    const [currentIndex, setCurrentIndex] = useState(0);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };

    // Automatically change slides every 3 seconds
    useEffect(() => {
        const intervalId = setInterval(nextSlide, 3000); // Change slide every 3 seconds
        return () => clearInterval(intervalId); // Cleanup on unmount
    }, [images.length]); // Re-run effect if the length of images changes

    let deptMenuLinks = [
        { name: 'About Department', path: '/dept/' + deptContent.branch + '/about-department' },
        { name: 'Department Portfolio', path: '/dept/' + deptContent.branch + '/department-portfolio' },
        { name: 'Digital Library', path: '/dept/' + deptContent.branch + '/digital-library' },
        { name: 'Faculties', path: '/dept/' + deptContent.branch + '/faculties' },
        { name: 'Result Analysis', path: '/dept/' + deptContent.branch + '/result-analysis' },
        { name: 'Various Events', path: '/dept/' + deptContent.branch + '/Various Events' },
        { name: 'MOUs', path: '/dept/' + deptContent.branch + '/mous' },
        { name: 'Laboratories', path: '/dept/' + deptContent.branch + '/laboratories' },
        { name: 'Curriculum & Syllabus', path: '/dept/' + deptContent.branch + '/curriculum' },
        { name: 'Time Table', path: '/dept/' + deptContent.branch + '/time-table' },
        { name: 'News Letter', path: '/dept/' + deptContent.branch + '/news-letter' },
        { name: 'Internal Revenue Generation', path: '/dept/' + deptContent.branch + '/internal-revenue-generation' },
    ];

    useEffect(() => {
        const labsData = async () => {
            let response = await fetch("https://gpsakoli.ac.in/public/api/connection.php?labs=" + deptContent.branch, {
                method: "GET"
            });

            let data = await response.json();
            setLabsData(data.data)
        }
        const mousData = async () => {
            let response = await fetch("https://gpsakoli.ac.in/public/api/connection.php?mouReports=" + deptContent.branch, {
                method: "GET"
            });

            let data = await response.json();
            setMousData(data.data)
        }
        const supportingStaffData = async () => {
            let response = await fetch("https://gpsakoli.ac.in/public/api/connection.php?supporting-staff-details", {
                method: "GET"
            });

            let data = await response.json();
            setSupportingStaffData(data.data)
        }
        const deptPortfolio = async () => {
            let response = await fetch("https://gpsakoli.ac.in/public/api/connection.php?dept-portfolio=" + deptContent.branch, {
                method: "GET"
            });

            let data = await response.json();
            setDeptPortfolioData(data.data)
            console.log(data)
        }

        const curriculum = async () => {
            let response = await fetch("https://gpsakoli.ac.in/public/api/connection.php?curriculum=" + deptContent.branch, {
                method: "GET"
            });

            let data = await response.json();
            setCurriculumData(data.data)
        }
        const irg = async () => {
            let response = await fetch("https://gpsakoli.ac.in/public/api/connection.php?irg=" + deptContent.branch, {
                method: "GET"
            });

            let data = await response.json();
            setIRGData(data.data)
            console.log(data.data);
        }
        const timeTable = async () => {
            let response = await fetch("https://gpsakoli.ac.in/public/api/connection.php?timeTable=" + deptContent.branch, {
                method: "GET"
            });

            let data = await response.json();
            setTimeTable(data.data)
            console.log(data.data);
        }
        const resultAnalysis = async () => {
            let response = await fetch("https://gpsakoli.ac.in/public/api/connection.php?resultAnalysis=" + deptContent.branch, {
                method: "GET"
            });

            let data = await response.json();
            setResultAnalysis(data.data)
        }
        const eventAnalysis = async () => {
            let response = await fetch("https://gpsakoli.ac.in/public/api/connection.php?eventAnalysis=" + deptContent.branch, {
                method: "GET"
            });

            let data = await response.json();
            setEventAnalysis(data.data)
            console.log(data.data);
        }
        eventAnalysis();
        resultAnalysis();
        timeTable();
        irg();
        curriculum();
        deptPortfolio();
        supportingStaffData();
        labsData();
        mousData()
        // staffData();
    }, [])

    return (
        <>
           
            <Header links={deptMenuLinks} />
            <div className="slider1" style={{ position: 'relative', textAlign: 'center' }}>
                <button className="slider-button prev1" onClick={prevSlide}>❮</button>
                <img src={images[currentIndex].src} alt={`Slide ${currentIndex + 1}`} className="slide-image1" />
                <div className="image-name-container">
                    <p className="image-name">{images[currentIndex].caption}</p>
                </div>
                <button className="slider-button next1" onClick={nextSlide}>❯</button>
            </div>
    
            <div className="main-container1">
                <div className="text-center">
                    <h1 className='heading'>Department of {deptContent.branch === 'computer' ? 'Computer Technology' : deptContent.branch === 'civil' ? 'Civil Engineering' : deptContent.branch === 'electrical' ? 'Electrical Engineering' : deptContent.branch === 'mechanical' ? 'Mechanical Engineering' : deptContent.branch === 'workshop' ? 'Workshop' : deptContent.branch === 'applied_mechanics' ? 'Applied Mechanics' : deptContent.branch === 'science_humanities' ? 'Science and Humanities': deptContent.branch === 'electronics' ? 'Electronics & Tel. Comm. Engineering ' : ''}</h1>
                    <h1 className='heading heading-red'>
                    {deptContent.branch === 'electronics' && (
                        <>
                            <br />
                            
                        </>
                    )}

                        {deptContent.branch !== "electronics" && "NBA Accredited"}</h1>
                </div>
    
                <div className="dynamic-container">
                    <div className="dynamic-change-container">
                        {deptContent.content === 'about-department' && <DeptAbout />}
                        {deptContent.content === 'department-portfolio' && <DeptPortfolio data={deptPortfolioData} />}
                        {deptContent.content === 'digital-library' && <DigitalLibrary />}
                        {deptContent.content === 'faculties' && <Suspense fallback={<p>Loading...</p>}>
                            <DeptFaculty supportingStaff={supportingStaffData} />
                        </Suspense>}
                        {deptContent.content === 'curriculum' && <DeptCurriculum curriculum={curriculumData} />}
                        {deptContent.content === 'result-analysis' && <DeptResultAnalysis data={resultAnalysis} />}
                        {deptContent.content === 'Various Events' && <DeptEventAnalysis data={eventAnalysis} />}
                        {deptContent.content === 'mous' && <DeptMous MousData={mousData} />}
                        {deptContent.content === 'laboratories' && <DeptLaboratory LabsData={labsData} />}
                        {deptContent.content === 'time-table' && <DeptTimeTable data={timeTable} />}
                        {deptContent.content === 'news-letter' && <DeptNewsLetter />}
                        {deptContent.content === 'internal-revenue-generation' && <DeptIRG data={irg} />}
                    </div>
                    <SideMenu links={deptMenuLinks} />
                </div>
            </div>
        </>
    );
}    