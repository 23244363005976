import { useState } from "react";
import React from "react";
import galleryImg1 from "../../images/hostel/gh3.jpg";
import ImageViewer from "../ImageViewer";
import { FiPlus, FiMinus } from "react-icons/fi"; // Import icons from react-icons

const galleryImages = [galleryImg1, ];

export default function GirlsHostel() {

const [openIndex, setOpenIndex] = useState(null);

  const norms = [
    
    {
      name: "Girls Hostel Committees ",
      url: "https://gpsakoli.ac.in/public/api/assets/MOUs_PDFs/computer/Girls%20Hostel%20Committees.pdf",
    },
  ];

  const togglePDF = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const [showImageViewer, setShowImageViewer] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const openImageViewer = (index) => {
    setCurrentImageIndex(index);
    setShowImageViewer(true);
  };

  const closeImageViewer = () => {
    setShowImageViewer(false);
  };

  const goToNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === galleryImages.length - 1 ? 0 : prevIndex + 1
    );
  };

  const goToPrevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? galleryImages.length - 1 : prevIndex - 1
    );
  };

  return (
    <>
      <h1 className="heading heading-top-left-line">Girls Hostel</h1>
      <hr className="my-6" />
      <div className="container mx-auto reveal">
        <h2 className="sub-heading">Hostel Images</h2>
        <div className="grid grid-cols-2 gap-4">
          {galleryImages.map((element, index) => (
            <img
              key={index}
              className="galleryImg"
              width={250}
              height={150}
              src={element}
              alt={`Hostel Image ${index + 1}`}
              onClick={() => openImageViewer(index)}
            />
          ))}
        </div>
        {showImageViewer && (
          <ImageViewer
            images={galleryImages}
            onClose={closeImageViewer}
            currentIndex={currentImageIndex}
            onNext={goToNextImage}
            onPrev={goToPrevImage}
          />
        )}
      </div>
      <br />

<div className="container mx-auto p-4">
<hr className="my-6" />
      <h1 className="sub-heading "> Girls Hostel Committees</h1>
      <ul className="space-y-3">
        {norms.map((norm, index) => (
          <li
            key={index}
            className="p-4 shadow-md "
            style={{
              background: "linear-gradient(160deg, var(--primary-bg-red), var(--card-linear-dark-red))",
            }}
          >
            <button
              onClick={() => togglePDF(index)}
              className="w-full flex justify-between items-center text-lg font-semibold text-white hover:underline focus:outline-none"
            >
              <span> {norm.name}</span>
              {openIndex === index ? <FiMinus size={20} /> : <FiPlus size={20} />}
            </button>

            {openIndex === index && (
              <div className="mt-3 border rounded-lg overflow-hidden">
                <iframe
                  src={norm.url}
                  width="100%"
                  height="500px"
                  className="border-none"
                  title={norm.name}
                ></iframe>
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>

      
      <div className="instruction-container">
      <hr className="my-6" />
  <h2 className="sub-heading ">Instructions</h2>
  <ul>
    <li>Hostel timings are from 7:00 am to 6:30 pm.</li>
    <li>A fine of Rs. 100/- in cash will be levied for late arrival at the hostel without a proper reason.</li>
    <li>The time for playing or sitting on the ground in the hostel building is from 7:00 AM to 6:30 PM.</li>
    <li>Time of attendance for registering presence in hostel is from 7:00 AM to 6:30 PM. Attendance will be compulsory.</li>
    <li>Peace should be maintained in the hostel during the examination so that the studies of other students are not disturbed.</li>
    <li>Opening time of the geyser is from 6:00 AM to 10:00 PM.</li>
    <li>While going out of the village, it is mandatory to take the permission of the Warden or Rector by noting on the form where to go.</li>
    <li>If you want to go out of the institution during the day, it is mandatory to take an outpass and register in the Entry Register.</li>
    <li>Garbage should be disposed of properly.</li>
    <li>Do not put food in the basin.</li>
    <li>Use of private electrical appliances (e.g., heater, cooler, press, hair straightener, hair dryer, etc.) is prohibited in the hostel.</li>
    <li>It is mandatory to switch off the light and fan while locking the room.</li>
    <li>Take care of your belongings and valuables yourself.</li>
    <li>In case of ill health, advance notice should be given to the Warden or Rector.</li>
    <li>In case of any kind of misbehavior in the hostel premises, as per rules, action will be taken.</li>
  </ul>
</div>

    </>
  );
}
