import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { FiPlus, FiMinus } from "react-icons/fi";
import { Slider } from '../components/Slider'; // Assuming Slider component is in ../components/Slider.js

const committeeData = {
    "women-grievance-commitee": {
        CommitteeName: "Women's Grievance Committee ",
        SessionName: "2023-24",
        columns: ["Sr No.", "Member Name", "Designation", "Contact"],
        rows: [
            ["1", "Member 1", "Designation 1", "Contact 1"],
            ["2", "Member 2", "Designation 2", "Contact 2"],
        ],
        pdfs: [
            {
                name: "Women's Grievance Committee",
                url: "https://gpsakoli.ac.in/public/api/assets/MOUs_PDFs//Women's%20Grievance%20Redressal%20Committee.pdf",
            },
        ],
    },
    "student-grievance-committee": {
        CommitteeName: "Student Grievance Committee ",
        SessionName: "2023-24",
        columns: ["Sr No.", "Student Name", "Year", "Contact"],
        rows: [
            ["1", "Student A", "2nd Year", "Contact A"],
            ["2", "Student B", "3rd Year", "Contact B"],
        ],
        pdfs: [
            {
                name: "Student Grievance PDF",  // Replace with a real PDF name and URL
                url: "https://gpsakoli.ac.in/public/api/assets/MOUs_PDFs//Women's%20Grievance%20Redressal%20Committee.pdf", // **REPLACE THIS WITH A REAL URL**
            },
        ],
    },
    "anti-ragging-committee": {
        CommitteeName: "Anti Ragging Committee ",
        SessionName: "2023-24",
        columns: ["Sr No.", "Faculty Name", "Designation", "Contact"],
        rows: [
            ["1", "Faculty X", "Designation X", "Contact X"],
            ["2", "Faculty Y", "Designation Y", "Contact Y"],
        ],
        pdfs: [
            {
                name: "Anti-Ragging Committees", // Replace with a real PDF name and URL
                url: "https://gpsakoli.ac.in/public/api/assets/MOUs_PDFs//AntiRagging%20Comitte.pdf", // **REPLACE THIS WITH A REAL URL**
            },
            {
                name: "Anti-Ragging Squad", // Replace with a real PDF name and URL
                url: "https://gpsakoli.ac.in/public/api/assets/MOUs_PDFs//AntiRagging%20squad.pdf", // **REPLACE THIS WITH A REAL URL**
            },
            
        ],
    },
    // ... (Add data for other committees similarly)
    "sc-st-committee": {
        CommitteeName: "SC-ST Committee Committee ",
        SessionName: "2023-24",
        columns: ["Sr No.", "Member Name", "Designation", "Contact"],
        rows: [
            ["1", "Member AA", "Designation AA", "Contact AA"],
            ["2", "Member BB", "Designation BB", "Contact BB"],
        ],
        pdfs: [
            {
                name: "SC-ST Committee PDF", // Replace with a real PDF name and URL
                url: "https://gpsakoli.ac.in/public/api/assets/MOUs_PDFs//scst%20order.pdf", // **REPLACE THIS WITH A REAL URL**
            },
        ],
    },
    "iste-committee": {
        CommitteeName: "ISTE/IEI Committee ",
        SessionName: "2023-24",
        columns: ["Sr No.", "Faculty Name", "Designation", "Contact"],
        rows: [
            ["1", "Faculty PP", "Designation PP", "Contact PP"],
            ["2", "Faculty QQ", "Designation QQ", "Contact QQ"],
        ],
        pdfs: [
            {
                name: "ISTE/IEI Committee PDF", // Replace with a real PDF name and URL
                url: "YOUR_ISTE_PDF_URL", // **REPLACE THIS WITH A REAL URL**
            },
        ],
    },
    "iic-committee": {
        CommitteeName: "IIC Committee ",
        SessionName: "2023-24",
        columns: ["Sr No.", "Member Name", "Role", "Contact"],
        rows: [
            ["1", "Member RR", "Role RR", "Contact RR"],
            ["2", "Member SS", "Role SS", "Contact SS"],
        ],
        pdfs: [
            {
                name: "IIC Committee PDF", // Replace with a real PDF name and URL
                url: "https://gpsakoli.ac.in/public/api/assets/MOUs_PDFs//internal%20Complaint%20Com.pdf", // **REPLACE THIS WITH A REAL URL**
            },
        ],
    },
    "iqac-committee": {
        CommitteeName: "IQAC Committee ",
        SessionName: "2023-24",
        columns: ["Sr No.", "Faculty Name", "Designation", "Contact"],
        rows: [
            ["1", "Faculty UU", "Designation UU", "Contact UU"],
            ["2", "Faculty VV", "Designation VV", "Contact VV"],
        ],
        pdfs: [
            {
                name: "IQAC Committee PDF", // Replace with a real PDF name and URL
                url: "YOUR_IQAC_PDF_URL", // **REPLACE THIS WITH A REAL URL**
            },
        ],
    },
    "nba-committee": {
        CommitteeName: "NBA Committee ",
        SessionName: "2023-24",
        columns: ["Sr No.", "Faculty Name", "Designation", "Contact"],
        rows: [
            ["1", "Faculty WW", "Designation WW", "Contact WW"],
            ["2", "Faculty XX", "Designation XX", "Contact XX"],
        ],
        pdfs: [
            {
                name: "NBA Committee PDF", // Replace with a real PDF name and URL
                url: "YOUR_NBA_PDF_URL", // **REPLACE THIS WITH A REAL URL**
            },
        ],
    },


};

export const GrievancesCommittee = () => {
    const { committee } = useParams();
    const selectedCommittee = committeeData[committee];
    const [openIndex, setOpenIndex] = useState(null);

    if (!selectedCommittee) {
        return <div>Committee not found.</div>;
    }

    return (
        <>
            <Slider />
            <h1 className="heading text-center">
                {selectedCommittee.CommitteeName} <span className="heading-red"> Committee</span>
            </h1>
            <div className="main-container">
                <div className="my-5">
                    <h1 className="sub-heading heading-top-left-line">
                        {selectedCommittee.CommitteeName} {/* ({selectedCommittee.SessionName})*/}
                    </h1>

                   

                 {/*   PDF Display Section */}
                    <div className="mt-5">
                        <ul className="space-y-3">
                            {selectedCommittee.pdfs && selectedCommittee.pdfs.map((pdf, index) => (
                                <li
                                    key={index}
                                    className="p-4 shadow-md"
                                    style={{
                                        background: "linear-gradient(160deg, var(--primary-bg-red), var(--card-linear-dark-red))",
                                    }}
                                >
                                    <button
                                        onClick={() => setOpenIndex(openIndex === index ? null : index)}
                                        className="w-full flex justify-between items-center text-lg font-semibold text-white hover:underline focus:outline-none"
                                    >
                                        <span>{pdf.name}</span>
                                        {openIndex === index ? <FiMinus size={20} /> : <FiPlus size={20} />}
                                    </button>

                                    {openIndex === index && (
                                        <div className="mt-3 border rounded-lg overflow-hidden">
                                            <iframe
                                                src={pdf.url}
                                                width="100%"
                                                height="500px"
                                                className="border-none"
                                                title={pdf.name}
                                            ></iframe>
                                        </div>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </div>

                    <hr className="my-6" />
          {/*            
      <h1 className="sub-heading "> Committees</h1>

 <table>
                        <thead>
                            <tr>
                                {selectedCommittee.columns.map((column, index) => (
                                    <th key={index}>{column}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {selectedCommittee.rows.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    {row.map((cell, cellIndex) => (
                                        <td key={cellIndex}>{cell}</td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>  */}

                </div>
            </div>
        </>
    );
};