import React from "react";
import Event1 from "../../images/Events/Electronics/e5.jpg";
import Event2 from "../../images/Events/Electronics/e3.jpg";
import Event3 from "../../images/Events/Electronics/e4.jpg";
import Event4 from "../../images/Events/Electronics/e1.jpg";
import Event5 from "../../images/Events/Electronics/ev2.jpg";
import Event6 from "../../images/Events/Electronics/ev3.jpg";
import Event7 from "../../images/Events/Electronics/ev4.jpg";
import Event8 from "../../images/Events/Electronics/ev1.jpg";



import {useParams} from "react-router-dom";
export default function DeptEventAnalysis() {
    const {branch} = useParams();
   
    
           
    const DeptEventAnalysisData = {
        
        computer: {
      description: <> 
      <br></br><br></br><br></br>
                  <h2 className="event-title">
                      Interactive session on "Arduino IDE & development trainer kit & peripherals" <br />
                      by Mr. Nikhil Temburne (Alumni - 2022)
                  </h2>
                  <h2 className="event-title1">
                      Date of Event : ( 21th January 2025 )
                  </h2>
      
                  {/* Flexbox for Horizontal Layout */}
                  <div className="event-gallery">
                      <img src={Event1} alt="Event 1" />
                      <img src={Event2} alt="Event 2" />
                      <img src={Event3} alt="Event 3" />
                      <img src={Event4} alt="Event 4" />
                  </div>
      
                  
      
                  <br></br><br></br><br></br>
                  <h2 className="event-title">
                      Expert talk on " Electronics Development in Japan & Career Opportunities in Foreign Languages " by Mr. Abhikesh Wale, Software developer, Xbit Inc., Tokyo Japan.
                  </h2>
                  <h2 className="event-title1">
                      Date of Event : ( 05th October 2024 )
                  </h2>
      
                  {/* Flexbox for Horizontal Layout */}
                  <div className="event-gallery">
                      <img src={Event8} alt="Event 1" />
                      <img src={Event5} alt="Event 2" />
                      <img src={Event6} alt="Event 3" />
                      <img src={Event7} alt="Event 4" />
                  </div></>,
        },
        civil: {
             description: <> 
      <br></br><br></br><br></br>
                  <h2 className="event-title">
                      Interactive session on "Arduino IDE & development trainer kit & peripherals" <br />
                      by Mr. Nikhil Temburne (Alumni - 2022)
                  </h2>
                  <h2 className="event-title1">
                      Date of Event : ( 21th January 2025 )
                  </h2>
      
                  {/* Flexbox for Horizontal Layout */}
                  <div className="event-gallery">
                      <img src={Event1} alt="Event 1" />
                      <img src={Event2} alt="Event 2" />
                      <img src={Event3} alt="Event 3" />
                      <img src={Event4} alt="Event 4" />
                  </div>
      
                  
      
                  <br></br><br></br><br></br>
                  <h2 className="event-title">
                      Expert talk on " Electronics Development in Japan & Career Opportunities in Foreign Languages " by Mr. Abhikesh Wale, Software developer, Xbit Inc., Tokyo Japan.
                  </h2>
                  <h2 className="event-title1">
                      Date of Event : ( 05th October 2024 )
                  </h2>
      
                  {/* Flexbox for Horizontal Layout */}
                  <div className="event-gallery">
                      <img src={Event8} alt="Event 1" />
                      <img src={Event5} alt="Event 2" />
                      <img src={Event6} alt="Event 3" />
                      <img src={Event7} alt="Event 4" />
                  </div></>,
        },
        mechanical: {
             description: <> 
      <br></br><br></br><br></br>
                  <h2 className="event-title">
                      Interactive session on "Arduino IDE & development trainer kit & peripherals" <br />
                      by Mr. Nikhil Temburne (Alumni - 2022)
                  </h2>
                  <h2 className="event-title1">
                      Date of Event : ( 21th January 2025 )
                  </h2>
      
                  {/* Flexbox for Horizontal Layout */}
                  <div className="event-gallery">
                      <img src={Event1} alt="Event 1" />
                      <img src={Event2} alt="Event 2" />
                      <img src={Event3} alt="Event 3" />
                      <img src={Event4} alt="Event 4" />
                  </div>
      
                  
      
                  <br></br><br></br><br></br>
                  <h2 className="event-title">
                      Expert talk on " Electronics Development in Japan & Career Opportunities in Foreign Languages " by Mr. Abhikesh Wale, Software developer, Xbit Inc., Tokyo Japan.
                  </h2>
                  <h2 className="event-title1">
                      Date of Event : ( 05th October 2024 )
                  </h2>
      
                  {/* Flexbox for Horizontal Layout */}
                  <div className="event-gallery">
                      <img src={Event8} alt="Event 1" />
                      <img src={Event5} alt="Event 2" />
                      <img src={Event6} alt="Event 3" />
                      <img src={Event7} alt="Event 4" />
                  </div></>,
        },
        electrical: {
      description: <> <br></br><br></br><br></br>
      <h2 className="event-title">
          Interactive session on "Arduino IDE & development trainer kit & peripherals" <br />
          by Mr. Nikhil Temburne (Alumni - 2022)
      </h2>
      <h2 className="event-title1">
          Date of Event : ( 21th January 2025 )
      </h2>

      {/* Flexbox for Horizontal Layout */}
      <div className="event-gallery">
          <img src={Event1} alt="Event 1" />
          <img src={Event2} alt="Event 2" />
          <img src={Event3} alt="Event 3" />
          <img src={Event4} alt="Event 4" />
      </div>

      

      <br></br><br></br><br></br>
      <h2 className="event-title">
          Expert talk on " Electronics Development in Japan & Career Opportunities in Foreign Languages " by Mr. Abhikesh Wale, Software developer, Xbit Inc., Tokyo Japan.
      </h2>
      <h2 className="event-title1">
          Date of Event : ( 05th October 2024 )
      </h2>

      {/* Flexbox for Horizontal Layout */}
      <div className="event-gallery">
          <img src={Event8} alt="Event 1" />
          <img src={Event5} alt="Event 2" />
          <img src={Event6} alt="Event 3" />
          <img src={Event7} alt="Event 4" />
      </div> </>,
        },
        electronics: {
             description: <> 
      <br></br><br></br><br></br>
                  <h2 className="event-title">
                      Interactive session on "Arduino IDE & development trainer kit & peripherals" <br />
                      by Mr. Nikhil Temburne (Alumni - 2022)
                  </h2>
                  <h2 className="event-title1">
                      Date of Event : ( 21th January 2025 )
                  </h2>
      
                  {/* Flexbox for Horizontal Layout */}
                  <div className="event-gallery">
                      <img src={Event1} alt="Event 1" />
                      <img src={Event2} alt="Event 2" />
                      <img src={Event3} alt="Event 3" />
                      <img src={Event4} alt="Event 4" />
                  </div>
      
                  
      
                  <br></br><br></br><br></br>
                  <h2 className="event-title">
                      Expert talk on " Electronics Development in Japan & Career Opportunities in Foreign Languages " by Mr. Abhikesh Wale, Software developer, Xbit Inc., Tokyo Japan.
                  </h2>
                  <h2 className="event-title1">
                      Date of Event : ( 05th October 2024 )
                  </h2>
      
                  {/* Flexbox for Horizontal Layout */}
                  <div className="event-gallery">
                      <img src={Event8} alt="Event 1" />
                      <img src={Event5} alt="Event 2" />
                      <img src={Event6} alt="Event 3" />
                      <img src={Event7} alt="Event 4" />
                  </div></>,
        },
        
        workshop: {
            description:<> <p><p className="text-red-500 text-2xl font-bold text-center mt-4">
            No Events data available for this branch !!
          </p></p></> 
          },
          applied_mechanics:{
            description:<> <p><p className="text-red-500 text-2xl font-bold text-center mt-4">
            No Events data available for this branch !!
          </p></p></> 
          },
          science_humanities:{
            description:<> <p><p className="text-red-500 text-2xl font-bold text-center mt-4">
            No Events data available for this branch !!
          </p></p></> 
          }
      
        };
    return (
        <>
           <div>
        <h1 className="heading heading-top-left-line">Events</h1>

                {branch === "computer" && DeptEventAnalysisData.computer.description}
                {branch === "civil" && DeptEventAnalysisData.civil.description}
                {branch === "mechanical" &&
                    DeptEventAnalysisData.mechanical.description}
                {branch === "electrical" &&
                    DeptEventAnalysisData.electrical.description}
                {branch === "electronics" &&
                    DeptEventAnalysisData.electronics.description}
                {branch === "workshop" && DeptEventAnalysisData.workshop.description}
                {branch === "applied_mechanics" && DeptEventAnalysisData.applied_mechanics.description}
                {branch === "science_humanities" && DeptEventAnalysisData.science_humanities.description}
            </div>
        
        </>
    );
}
