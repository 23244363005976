
import im from "../../images/institution-map/institution-map.jpg";
import React from "react";
import portfolio_details from "../../pdfs/portfolio-details/portfolioDistribution-2021-22.pdf";
export default function InstitutionMap() {
	return (
		<>
			<h1 className="heading heading-top-left-line"> Institute Map</h1>

<img src={im} width="100%" height="100%" alt="" />
		</>
	);
}
