import React, { useState } from "react";
import { FiPlus, FiMinus } from "react-icons/fi";
import { useParams } from "react-router-dom";

export default function DeptIRG() {
  const { branch } = useParams();
  const [openIndex, setOpenIndex] = useState(null);

  const togglePDF = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  // 📌 Different IRG Data for each branch (with Full URLs)
  const irgData = {
    computer: [
      { title: "Computer - Project 1", url: "https://gpsakoli.ac.in/irg/computer/project1.pdf" },
      { title: "Computer - Project 2", url: "https://gpsakoli.ac.in/irg/computer/project2.pdf" },
    ],
    civil: [
      { title: "Civil - Project 1", url: "https://gpsakoli.ac.in/irg/civil/project1.pdf" },
      { title: "Civil - Project 2", url: "https://gpsakoli.ac.in/irg/civil/project2.pdf" },
    ],
    mechanical: [
      { title: "Mechanical - Project 1", url: "https://gpsakoli.ac.in/irg/mechanical/project1.pdf" },
      { title: "Mechanical - Project 2", url: "https://gpsakoli.ac.in/irg/mechanical/project2.pdf" },
    ],
    electrical: [
      { title: "Electrical - Project 1", url: "https://gpsakoli.ac.in/public/api/assets/IRG_PDFs/computer/irgee.pdf" },
      
    ],
    electronics: [
      { title: "Electronics - Project 1", url: "https://gpsakoli.ac.in/irg/electronics/project1.pdf" },
      { title: "Electronics - Project 2", url: "https://gpsakoli.ac.in/irg/electronics/project2.pdf" },
    ],
  };

  const branchIRG = irgData[branch];

  return (
    <div >
      <h1 className="heading heading-top-left-line">Internal Revenue Generation </h1> <br></br>

      {branchIRG ? (
        <div className="irg-projects">
          {branchIRG.map((item, index) => (
            <div key={index} className="irg-item mb-4">
              <div
                className="p-4 shadow-md"
                style={{
                  background: "linear-gradient(160deg, var(--primary-bg-red), var(--card-linear-dark-red))",
                }}
              >
                <button
                  onClick={() => togglePDF(index)}
                  className="w-full flex justify-between items-center text-lg font-semibold text-white hover:underline focus:outline-none"
                  aria-expanded={openIndex === index ? 'true' : 'false'}
                >
                  <span>{item.title}</span>
                  {openIndex === index ? <FiMinus size={20} /> : <FiPlus size={20} />}
                </button>

                {openIndex === index && (
                  <div className="mt-3 border rounded-lg overflow-hidden">
                    <iframe
                      src={item.url}
                      width="100%"
                      height="500px"
                      className="border-none"
                      title={item.title}
                    ></iframe>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p><p className="text-red-500 text-2xl font-bold text-center mt-4">
        No IRG data available for this branch !!
      </p></p>
      )}
    </div>
  );
}
