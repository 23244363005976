import React, { useState, useEffect } from "react";
import libraryStaff from "../../images/staff-imgs/others/Library staff.jpg";

import libraryImage1 from "../../images/Library/lib1.jpg";
import libraryImage2 from "../../images/Library/lib2.jpg";
import libraryImage3 from "../../images/Library/lib3.jpg";
import libraryImage4 from "../../images/Library/lib4.jpg";

// Importing necessary libraries for the slider
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

export default function Library() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const images = [libraryImage1, libraryImage2, libraryImage3, libraryImage4];

  const goToPrevious = () => {
    const isFirstImage = currentIndex === 0;
    const newIndex = isFirstImage ? images.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const isLastImage = currentIndex === images.length - 1;
    const newIndex = isLastImage ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  // Automatically change the image every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      goToNext();
    }, 3000); // Change image every 3 seconds

    // Cleanup the interval when the component is unmounted
    return () => clearInterval(interval);
  }, [currentIndex]); // Dependency on currentIndex ensures the interval works properly

  return (
    <>
       <h1 className="heading heading-top-left-line">Library</h1>
       <hr className="my-6" />



       {/* 📌 Image Slider Section */}
       <div className="w-full relative p-4">
        <img
          src={images[currentIndex]}
          alt={`Library View ${currentIndex + 1}`}
          className="w-full h-80 object-cover shadow-lg transition-transform duration-300 ease-in-out"
        />
        <div className="absolute top-1/2 left-0 transform -translate-y-1/2 p-2">
  <FaChevronLeft
    onClick={goToPrevious}
	className="text-red text-4xl cursor-pointer bg-black bg-opacity-50 p-2  hover:bg-opacity-75 transition-all duration-300"  />
</div>
<div className="absolute top-1/2 right-0 transform -translate-y-1/2 p-2">
  <FaChevronRight
    onClick={goToNext}
    className="text-red text-4xl cursor-pointer bg-black bg-opacity-50 p-2  hover:bg-opacity-75 transition-all duration-300"
  />
</div>

      </div>

      <div className="about-card w-full p-4 my-6 border-card flex items-center">
        <img
          src={libraryStaff}
          alt="Library Staff"
          className="library-profile w-20 h-20 mr-6 "
        />
        <p className="text-lg leading-relaxed">
          Welcome to the Government Polytechnic Sakoli Library! We provide a comfortable and supportive learning environment with access to a
          vast collection of print and all resources, including books, journals, databases, and more. Our dedicated staff
          is here to assist you with your research needs.  
          <br />
          <strong>For Library-related inquiries, contact:</strong> Mr. Sheshrao N. Madavi (Mob: 9822470626).
        </p>
      </div>

     


      <div className="mt-5">
        <table border="1px">
          <thead>
            <tr>
              <td>Timing</td>
              <td>10.00 AM to 6.00 PM</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>No. of Library Staff</td>
              <td>03</td>
            </tr>
            <tr>
              <td>No. of Staff with degree in Library</td>
              <td>01</td>
            </tr>
            <tr>
              <td>Library Management Software</td>
              <td>Saral Lib Automation Software </td>
            </tr>
            <tr>
              <td>Computerization for search indexing</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>Issue/Return records bar coding used</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>Carpet Area of Library (in Sq.Mt.)</td>
              <td>3000 Sq. Mt.</td>
            </tr>
            <tr>
              <td>Reading Space (in Sq.Mt.)</td>
              <td>104 Sq. M.</td>
            </tr>
            <tr>
              <td>Number of seats in Reading Space</td>
              <td>75</td>
            </tr>
          </tbody>
        </table>
      </div>
      <hr className="my-6" />
      <div className="mt-5">
    
        <h1 className="heading heading-top-left-line">  No. of Titles & Volumes (Department wise): As per AICTE
        Rules</h1>
      
        <table border="1px">
          <thead>
            <tr>
              <td>Sr No.</td>
              <td>Branch/Course</td>
              <td>Titles</td>
              <td>Volumes</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>01</td>
              <td>Civil Engg.</td>
              <td>1424 </td>
              <td>7160</td>
            </tr>
            <tr>
              <td>02</td>
              <td>Electrical Engg.</td>
              <td>539</td>
              <td>2619</td>
            </tr>
            <tr>
              <td>03</td>
              <td>Computer Engg.</td>
              <td>1127</td>
              <td>2870</td>
            </tr>
            <tr>
              <td>04</td>
              <td>Electronics & Telecommunication Engg.</td>
              <td>1119</td>
              <td>5249</td>
            </tr>
            <tr>
              <td>05</td>
              <td>Mechanical Engg.</td>
              <td>894</td>
              <td>6029</td>
            </tr>
            <tr>
              <td>06</td>
              <td>General</td>
              <td>2220</td>
              <td>1452</td>
            </tr>
            <tr>
              <td>07</td>
              <td>Science</td>
              <td>491</td>
              <td>6830</td>
            </tr>
            <tr>
              <td></td>
              <td>
                <b>Total</b>
              </td>
              <td>
                <b>7814</b>
              </td>
              <td>
                <b>32209</b>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <hr className="my-6" />
      <div className="mt-5">
    
        <h1 className="heading heading-top-left-line">   No. of Journals ( Department wise): As Per AICTE Rules</h1>
        
        
        <table border="1px">
          <thead>
            <tr>
              <td>Sr No.</td>
              <td>Branch/Course</td>
              <td>Titles</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>01</td>
              <td>Civil Engg.</td>
              <td>04 </td>
            </tr>
            <tr>
              <td>02</td>
              <td>Electrical Engg.</td>
              <td>04</td>
            </tr>
            <tr>
              <td>03</td>
              <td>Computer Engg.</td>
              <td>05</td>
            </tr>
            <tr>
              <td>04</td>
              <td>Electronics & Telecommunication Engg.</td>
              <td>04</td>
            </tr>
            <tr>
              <td>05</td>
              <td>Mechanical Engg.</td>
              <td>03</td>
            </tr>
            <tr>
              <td>06</td>
              <td>General</td>
              <td>02</td>
            </tr>
            <tr>
              <td>07</td>
              <td>Science</td>
              <td>01</td>
            </tr>
            <tr>
              <td></td>
              <td>
                <b>Total</b>
              </td>
              <td>
                <b>23</b>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="mt-5">
        <table border="1px">
          <tbody>
            <tr>
              <td>Computerized Lab.</td>
              <td>Yes </td>
            </tr>
            <tr>
              <td>No. of Computers available in Lab.</td>
              <td>20</td>
            </tr>
            <tr>
              <td>Internet Connectivity</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>All Books Barcoding</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>OPAC System</td>
              <td>Yes</td>
            </tr>
            <tr>
              <td>Book Issue / Return By Library Software</td>
              <td>Yes</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}
