import React, { useState, useEffect } from "react"; // Import useEffect
import { Slider, News_slider, Department_slider } from "../components/Slider";
import Image_Background from "../components/Image_Background";
import Vision_Mission from "../components/Vision_Mission";
import Recruiters from "../components/Recruiters";
import { Link } from "react-router-dom";
import galleryImg1 from "../images/college-images/one.jpg";
import galleryImg2 from "../images/college-images/two.jpg";
import galleryImg3 from "../images/college-images/three.jpg";
import galleryImg4 from "../images/college-images/four.jpg";
import galleryImg5 from "../images/college-images/five.jpg";
import galleryImg6 from "../images/college-images/six.jpg";
import galleryImg7 from "../images/college-images/seven.jpg";
import galleryImg8 from "../images/college-images/eight.jpg";
import galleryImg9 from "../images/college-images/nine.jpg";
import galleryImg10 from "../images/icons/blinking_new (1).gif";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import Footer from "../components/Footer"
import SideMenu from '../components/SideMenu';
import AboutInstitute from '../components/about-components/AboutInstitute'
import AboutProgramOffered from '../components/about-components/AboutProgramOffered'
import AboutPrincipalRes from '../components/about-components/AboutPrincipalRes';
import OfficeStaff from '../components/about-components/OfficeStaff';
import AcademicCalendar from '../components/about-components/AcademicCalender';
import OrganizationStructure from '../components/about-components/OrganizationStructure';
import PortfolioDetails from '../components/about-components/PortfolioDetails';
import Preloader from '../components/Preloader';
import InstitutionMap from '../components/about-components/InstitutionMap';
import { useParams } from 'react-router-dom';
import { Norms } from '../components/about-components/Norms';
import { RightToInformation } from '../components/about-components/RightToInformation';
import { InstituteCalender } from '../components/about-components/InstituteCalender';
import { CitizenCharter } from '../components/about-components/CitizenCharter';



import news from "../images/news/7May2022.jpeg";

// Importing image
import principal from "../images/principal.png";
import ImageViewer from "../components/ImageViewer";

const notices = [
  { 
    url: "https://gpsakoli.ac.in/public/api/assets/NewsLetters_PDFs/computer/%E0%A4%9C%E0%A4%BE%E0%A4%B9%E0%A5%80%E0%A4%B0%E0%A4%BE%E0%A4%A4.pdf", 
    msg: (
      <div style={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <u>Vacancy position for FY admission 2024-25</u>
        
      </div>
    )
  },
 
];
const newsImages = [
  require("../images/news/Induction_Program_21sep22.jpeg"),
  require("../images/news/15sep_22_engday.jpeg"),
  require("../images/news/Jaldharohar_18Sep22.jpeg"),
  require("../images/news/7May2022.jpeg"),
  require("../images/news/tiranga_12aug22.jpeg")

];

// Change Images from here
const galleryImages = [
  galleryImg1,
  galleryImg2,
  galleryImg3,
  galleryImg4,
  galleryImg5,
  galleryImg6,
  galleryImg7,
  galleryImg8,
  galleryImg9,
];

function reveal() {
  var reveals = document.querySelectorAll(".reveal");

  for (var i = 0; i < reveals.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top;
    var elementVisible = 150;

    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add("active");
    } else {
      reveals[i].classList.remove("active");
    }
  }
}

window.addEventListener("scroll", reveal);

export default function Home() {


   const { page } = useParams();
      
      let aboutMenuLinks = [
          { name: 'About The Institute', path: '/about-institute/about-institute' },
          { name: 'Program Offered', path: '/about-institute/program-offered' },
          { name: 'Principal Responsibilities', path: '/about-institute/principal-res' },
          { name: 'Office Staff', path: '/about-institute/office-staff' },
          { name: 'Organization Structure', path: '/about-institute/organization-structure' },
          { name: 'Institute Map', path: '/about-institute/institute-map' },
          { name: 'Portfolio Details', path: '/about-institute/portfolio-details' },
          { name: 'Academic Calender', path: '/about-institute/academic-calender' },
          { name: 'Norms', path: '/about-institute/norms' },
          { name: 'Right To Information', path: '/about-institute/right_to_information' },
          { name: 'Institute Calender', path: '/about-institute/institute-calender' },
          { name: 'Citizen Charter', path: '/about-institute/citizen-charter' }
      ];



  const [showImageViewer, setShowImageViewer] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const openImageViewer = (index) => {
    setCurrentImageIndex(index);
    setShowImageViewer(true);
  };

  const closeImageViewer = () => {
    setShowImageViewer(false);
  };

  const goToNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === galleryImages.length - 1 ? 0 : prevIndex + 1
    );
  };

  const goToPrevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? galleryImages.length - 1 : prevIndex - 1
    );
  };

  // Use useEffect to add the chatbot scripts
  useEffect(() => {
    const script1 = document.createElement("script");
    script1.src = "https://cdn.botpress.cloud/webchat/v2.2/inject.js";  
    
    script1.async = true;
  
    const script2 = document.createElement("script");
    script2.src = "https://files.bpcontent.cloud/2024/10/25/03/20241025031819-X8JAN06Q.js";
    script2.async = true;
  
    document.body.appendChild(script1);
    document.body.appendChild(script2);
  
    return () => {
      document.body.removeChild(script1);
      document.body.removeChild(script2);
    };
  }, []);// Empty dependency array ensures it runs once when the component mounts

  return (
    <>


              <>
                  <Slider />
                  <div className="main-container">
                      <div className="text-center">
                          <h1 className='heading my-8'>About <span className="heading-red"> Institute</span></h1>
                      </div>

                      <>
        <div className="flex flex-row"> {/* Flex column container */}
    <div className="institute-desc text-justify p-7">
        <p>
            Government Polytechnic Sakoli is a technical institute located in
            Sendurwafa, Maharashtra, India. Established in 1983,<br></br> it offers diploma
            programs in various Branch like - Computer Technology, Electronics & Telecommunication, Civil Engineering, Mechanical Engineering, Electrical Engineering.
        </p>
    </div>

    <div className="top-4 right-4 w-[400px] max-w-[90%] mx-auto p-4 rounded-lg shadow-md border border-red-200"
     style={{ background: "linear-gradient(160deg, var(--primary-bg-red), var(--card-linear-dark-red))" }}>

    <div className="notice-heading my-2 flex flex-col items-center">
        <span className="underline bg-blue-500 h-1 w-1/2"></span>
        <h1 className="text-xl font-bold text-gray-100">Notice Board</h1>
    </div>
    <div className="notices-container max-h-48 overflow-y-auto flex flex-col-reverse"> {/* Key changes here */}
        <div className="notice-inner-container">
            {notices.map((notice) => (
                <a key={notice.url} target="_blank" rel="noopener noreferrer" href={notice.url} className="text-gray-100 hover:underline hover:text-yellow-200">
                    {notice.msg}
                </a>
            ))}
        </div>
    </div>
</div>
</div> 
           </>

                 <div className="dynamic-container">
                          <div className="institute-about-container">
                                                       
                          <div className="home-container">
       
        
      </div>
      



      <div>
              
      <div className="mr-8"> 
    <img className="ml-2 pl-2" src={principal} alt="" width={"150px"} height={"150px"} /> {/* Added padding */}
    <div className="principal-name mx-1 pl-2">
        <div>Mr. S P Lambhade</div>
        <span>Principal.</span>
    </div>
</div>
               <p className="institute-desc text-justify p-2">
              "Welcome to G.P. Sakoli, where we are dedicated to nurturing the next generation of skilled professionals. Our institution is committed to providing a comprehensive and quality education that equips students with the practical knowledge and technical skills necessary to excel in today's competitive world.
              At G.P. Sakoli, we believe in a holistic approach to education, focusing not only on academic excellence but also on personal and professional development. Our experienced faculty members are passionate about their fields and are dedicated to guiding students towards success. We offer state-of-the-art facilities and modern infrastructure to create a conducive learning environment.
              Our goal is to produce graduates who are not only technically proficient but also socially responsible and ethical citizens. We encourage our students to actively participate in extracurricular activities, internships, and industry collaborations to gain practical experience and broaden their horizons.
              We invite you to explore our website to learn more about our programs, facilities, and achievements. I look forward to welcoming you to the G.P. Sakoli family!"
            </p>
</div>
      
                                                     {page === 'about-institute' && <AboutInstitute />}
                                                     {page === 'program-offered' && <AboutProgramOffered />}
                                                     {page === 'principal-res' && <AboutPrincipalRes />}
                                                     {page === 'office-staff' && <OfficeStaff />}
                                                     {page === 'organization-structure' && <OrganizationStructure />}
                                                     {page === 'institute-map' && <InstitutionMap />}
                                                     {page === 'portfolio-details' && <PortfolioDetails />}
                                                     {page === 'academic-calender' && <AcademicCalendar />}
                                                     {page === 'norms' && <Norms />}
                                                     {page === 'right_to_information' && <RightToInformation />}
                                                     {page === 'institute-calender' && <InstituteCalender />}
                                                     {page === 'citizen-charter' && <CitizenCharter />}
                          </div>
                          <SideMenu links={aboutMenuLinks} />
         

                      </div>
                  </div>

                  
              </>
      
      <div className=" mt-10 pt-9">
        <Vision_Mission
          vision={
            "To be an institute of national repute creating technocrats to serve the society."
          }
          mission={[
            "To set up state of the art infrastructure, laboratories, library and supporting services.",
            "To achieve academic excellence in teaching and learning through continuous development using latest technologies and resources.",
            "To inculcate technical and entrepreneurial skills, moral and ethical values in students.",
            "To build strategic networking with alumni, industries and academic institutions.",

            
          ]
        
        }
        />
        
        <br></br><br></br>
        <div className="home-po-gallery-container grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 gap-8">
          <div className="home-po-container">
            <h1 className="relative heading-top-left-line">
              Program <span className="heading-red">Offered</span>
            </h1>
            <div className="program-outcome-content reveal">
           
			<table border="1px">
				<thead>
					<tr>
						<td>Sr. No.</td>
						<td>Branch</td>
						<td>Intake</td>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>1</td>
						<td>Computer Technology</td>
						<td>60</td>
					</tr>
					<tr>
						<td>2</td>
						<td>Civil Engineering</td>
						<td>60</td>
					</tr>
					<tr>
						<td>3</td>
						<td>Mechanical Engineering</td>
						<td>60</td>
					</tr>
					<tr>
						<td>4</td>
						<td>Electrical Engineering</td>
						<td>60</td>
					</tr>
					<tr>
						<td>5</td>
						<td>Electronics and Telecommunications Engineering</td>
						<td>30</td>
					</tr>
				</tbody>
			</table>
            </div>
          </div>
          <div className="news-paper-card border-card reveal max-w-[50%] mx-auto p-4 ">
  <h1 className="text-center font-bold">
    GP Sakoli In <span className="heading-red">News</span>
  </h1>

  {/* News Slider */}
  <div className="news-slider-container  ">
    <Swiper
      modules={[Navigation, Pagination, Autoplay]}
      spaceBetween={10}
      slidesPerView={1}
      navigation
      pagination={{ clickable: true }}
      autoplay={{ delay: 3000 }}
      loop={true}
    >
      {newsImages.map((image, index) => (
        <SwiperSlide key={index}>
          <img src={image} alt={`News ${index + 1}`} className="news-slide-image" />
        </SwiperSlide>
      ))}
    </Swiper>
  </div>

  {/* View More News */}
  <div className="news-container text-center mt-3">
    <Link to="/gps-in-news" className="btn btn-primary">
      View More News
    </Link>
  </div>
</div>

          
        </div>
        <Recruiters
         />
      </div>

      
    </>
  );
}
