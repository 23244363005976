import React from "react";
import {useParams} from "react-router-dom";
// import Image_Background from "../components/Image_Background";
import Vision_Mission from "../Vision_Mission";
// import Recruiters from "../components/Recruiters";
export default function DeptAbout() {
	const {branch} = useParams();
	const DeptAboutData = {
		computer: {
			description: `Computer Engineering is core branch among other
		branches. Computer engineering continually push the
		capability and applicability of computer in every
		industry and every facet of modern life. it embeds
		computers in other machines and system, build network to
		transfer data and develop way to make computer faster,
		smaller and more capable. The pass outs have
		opportunities to work as Customer support Engineer,
		Programmer, Maintenance Engineer and hardware Engineer
		in various sector or companies like IBM, TCS, WIPRO,
		persistent software, tech. Mahindra, Infosys etc.
		Students can also start their own career in small
		software development firms or Hardware Maintenance
		firms.`,
			vision: `To produce competent Computer Engineers for development of the society.`,
			mission: [
				`To impart excellent technical knowledge through modern infrastructure and laboratories.`,
				`To promote innovative thinking in the minds of budding engineers.`,
				`To develop the students competent to face the challenges incorporating technical and entrepreneurship skills.`,
				`To inculcate moral and ethical values.`,
			],
			poes: [
				`PEO1: Provide socially responsible, environment friendly solutions to Computer engineering related broad-based problems adapting professional ethics.`,
				`PEO2: Adapt state-of-the-art computer engineering broad-based technologies to work in multi-disciplinary work environments.`,
				`PEO3: Solve broad-based problems individually and as a team member communicating effectively in the world of work.`,
			],
			psos: [
				`
					PSO1: Computer Software and Hardware Usage: Use state-of-the-art technologies for operation and application of computer software and hardware.`,
				`PSO2: Computer Engineering Maintenance: Maintain computer engineering realted software and hardware systems.`,
			],
			pos: [
				`PO1:Basic and Discipline Specific Knowledge: Apply knowledge of basic mathematics, science and engineering fundamentals and engineering specialization to solve the engineering problems.`,
				`PO2: Problem analysis: Identify and analyse well-defined engineering problems using codified standard methods.`,
				`PO3: Design/ development of solutions: Design solutions for well-defined technical problems and assist with the design of systems components or processes to meet specified needs.`,
				`PO4: Engineering Tools, Experimentation and Testing: Apply modern engineering tools and appropriate technique to conduct standard tests and measurements.`,
				`PO5: Engineering practices for society, sustainability and environment: Apply appropriate technology in context of society, sustainability, environment and ethical practices.`,
				`PO6: Project Management: Use engineering management principles individually, as a team member or a leader to manage projects and effectively communicate about well-defined engineering activities.`,
				`PO7: Life-long learning: Ability to analyses individual needs and engage in updating in the context of technological change.`,
			],
		},
		civil: {
			description: `The department activities embraces planning, design construction and management. The faculties are highly qualified, experienced, dedicated and dynamic. As per MSBTE norms, the department is enriched with well-equipped laboratories and software to enhance the knowledge of students to produce engineers competent and capable of serving the nation in the world of globalization. The department is pioneer in providing services to the other departments, like testing of soil and construction material, consultancies and technical audit of civil engineering works which helps for internal revenue generation to the government.`,
			vision: `To produce competent Civil Engineers for development of the society.`,
			mission: [
				`To impart excellent technical knowledge through modern infrastructure and laboratories.`,
				`To promote innovative thinking in the minds of budding engineers.`,
				`To develop the students competent to face the challenges incorporating technical and entrepreneurship skills.`,
				`To inculcate moral and ethical values.`,
			],
			poes: [
				`PEO1: Provide socially responsible, environment friendly solutions to Civil engineering related broad-based problems adapting professional ethics.`,
				`PEO2: Adapt state-of-the-art Civil engineering broad-based technologies to work in multidisciplinary work environments.`,
				`PEO3: Solve broad-based problems individually and as a team member communicating effectively in the world of work.`,
			],
			psos: [
				`PSO1: Construction Planning and Designing: Perform optimal civil engineering construction, planning and designing activities of desired quality at optimal cost.`,
				`PSO1: Construction Execution and Maintenance: Execute civil engineering construction and maintenance using relevant materials and equipment.`,
			],
			pos: [
				`PO1:Basic and Discipline Specific Knowledge: Apply knowledge of basic mathematics, science and engineering fundamentals and engineering specialization to solve the engineering problems.`,
				`PO2: Problem analysis: Identify and analyse well-defined engineering problems using codified standard methods.`,
				`PO3: Design/ development of solutions: Design solutions for well-defined technical problems and assist with the design of systems components or processes to meet specified needs.`,
				`PO4: Engineering Tools, Experimentation and Testing: Apply modern engineering tools and appropriate technique to conduct standard tests and measurements.`,
				`PO5: Engineering practices for society, sustainability and environment: Apply appropriate technology in context of society, sustainability, environment and ethical practices.`,
				`PO6: Project Management: Use engineering management principles individually, as a team member or a leader to manage projects and effectively communicate about well-defined engineering activities.`,
				`PO7: Life-long learning: Ability to analyses individual needs and engage in updating in the context of technological change.`,
			],
		},
		mechanical: {
			description: `Establishment in 1990, this department has experienced faculty and well equipped Laboratories with investment totaling around 1.1 crores. The department has delivered consistently good result in board examination with regular campus placement in well-known industries. Good liaison with industries is maintained to provide adequate industry exposure to students through industrial visit and expert talks. Extracurricular activities and involvement in sports is emphasized to develop all round personality of the students. Faculty from the department is actively involved in technical consultancy services.`,
			vision: `To produce competent Mechanical Engineers for development of the society.`,
			mission: [
				`To impart excellent technical knowledge through modern infrastructure and laboratories.`,
				`
			To promote innovative thinking in the minds of budding engineers.`,
				`
			To develop the students competent to face the challenges incorporating technical and entrepreneurship skills.`,
				`
			To inculcate moral and ethical values.`,
			],
			poes: [
				`PEO1: Provide socially responsible, environment friendly solutions to Mechanical engineering related broad-based problems adapting professional ethics.`,
				`PEO2: Adapt state-of-the-art Mechanical engineering broad-based technologies to work in multi-disciplinary work environments.`,
				`PEO3: Solve broad-based problems individually and as a team member communicating effectively in the world of work.`,
			],
			psos: [
				`PSO1: Modern Software Usage: Use latest Mechanical engineering related softwares for simple design, drafting, manufacturing, maintenance and documentation of mechanical engineering components and processes.`,
				`PSO2: Equipment and Instruments: Maintain equipment and instruments related to Mechanical Engineering.`,
				`PSO3: Mechanical Engineering Processes: Manage Mechanical engineering processes by selecting and scheduling relevant equipment, substrates, quality control techniques, and operational parameters.`,
			],
			pos: [
				`PO1:Basic and Discipline Specific Knowledge: Apply knowledge of basic mathematics, science and engineering fundamentals and engineering specialization to solve the engineering problems.`,
				`PO2: Problem analysis: Identify and analyse well-defined engineering problems using codified standard methods.`,
				`PO3: Design/ development of solutions: Design solutions for well-defined technical problems and assist with the design of systems components or processes to meet specified needs.`,
				`PO4: Engineering Tools, Experimentation and Testing: Apply modern engineering tools and appropriate technique to conduct standard tests and measurements.`,
				`PO5: Engineering practices for society, sustainability and environment: Apply appropriate technology in context of society, sustainability, environment and ethical practices.`,
				`PO6: Project Management: Use engineering management principles individually, as a team member or a leader to manage projects and effectively communicate about well-defined engineering activities.`,
				`PO7: Life-long learning: Ability to analyses individual needs and engage in updating in the context of technological change.`,
			],
		},
		electrical: {
			description: `The department has taken development as a continuous process and assured this through its vision and missions. The department has blend of senior, young and dynamic faculty.All these laboratories are well equipped with Digital and Analog measuring instruments, modern equipment’s and AC / DC electrical machines.`,
			vision: `To produce competent Electrical Engineers for development of the society`,
			mission: [
				`To impart excellent technical knowledge and skills through modern Infrastructure & laboratories`,
				`
			To promote innovative thinking in the minds of budding engineers`,
				`
			To develop the student competent to face the challenges incorporating technical & entrepreneurship skills`,
				`
			To inculcate moral and ethical values`,
			],
			poes: [
				`PEO1: Provide socially responsible, environment friendly solutions to Electrical engineering related broad-based problems adapting professional ethics.`,
				`PEO2: Adapt state-of-the-art Electrical engineering broad-based technologies to work in multi-disciplinary work environments.`,
				`PEO3: Solve broad-based problems individually and as a team member communicating effectively in the world of work.`,
			],
			psos: [
				`PSO1: Electrical Equipment: Maintain various types of rotating and static electrical equipment.`,
				`PSO2: Electric Power Systems: Maintain different types of electrical power systems`,
			],
			pos: [
				`PO1:Basic and Discipline Specific Knowledge: Apply knowledge of basic mathematics, science and engineering fundamentals and engineering specialization to solve the engineering problems.`,
				`PO2: Problem analysis: Identify and analyse well-defined engineering problems using codified standard methods.`,
				`PO3: Design/ development of solutions: Design solutions for well-defined technical problems and assist with the design of systems components or processes to meet specified needs.`,
				`PO4: Engineering Tools, Experimentation and Testing: Apply modern engineering tools and appropriate technique to conduct standard tests and measurements.`,
				`PO5: Engineering practices for society, sustainability and environment: Apply appropriate technology in context of society, sustainability, environment and ethical practices.`,
				`PO6: Project Management: Use engineering management principles individually, as a team member or a leader to manage projects and effectively communicate about well-defined engineering activities.`,
				`PO7: Life-long learning: Ability to analyses individual needs and engage in updating in the context of technological change.`,
			],
		},
		electronics: {
			
			description: `As we step into the New Year 2025, let's first pause and thank God for keeping us in good health and spirit to be in this moment. Last few years has gone very tough. It has indeed been an emotionally testing and turbulent one and taught many lessons. I am sure the year 2025 shall bring new opportunities and hopes for all of us. Undoubtedly, everyone contributed to keeping the Educational and other activities in motion during the lockdown period. On reopening, earnest efforts were made at DETE (Dept. of Electronics and Telecommunication Engineering, Govt. Polytechnic, Sakoli) to speed up the critical tasks and make up for the time lost during the containment period. The DETE has started online classes one week before the commencement of academic activities by MSBTE. The DETE has conducted practical's using various online mechanisms, including youtube videos, live demonstrations, etc.

Also, the DETE has conducted a physical workshop on "PCB Design Simulation & Implementation" by following the Govt. norms of Covid-19. DETE is doing all activities as per the guidelines of MSBTE, DTE, AICTE, and Govt, norms of Covid-19. The result of the collective efforts may not be apparent right now but shall in the near future. Our day-to-day teaching work has taken a new definition and dimension in the overwhelming challenges of COVID-19. A surge in online usage during Pandemic offered us an opportunity to develop new ways to deliver our lectures, conduct practical's and PA tests to build stronger relationships with students and the community. Of course, such technological challenges will require us to make significant changes in the ways. we operate. Still, I am happy to say DETE is undertaking the necessary steps in the direction in all possible capacities.

Finally, I wish readers and their families a delighted and prosperous new year. Stay safe and beat the Pandemic with new energy`,
vision: `" To develop ethical and  competent technical profesionals by providing sound knowledge, skills and human vales to cater the need of stakeholders. "`,
	
mission: [
				`To grow and raise the professional, intellectual, social and personal abilities of the students.`,
				`To keep pace with state of art technology & time.`,
				`To provide training to Faculty and staff.`,
				`To build liaison with stakeholder.`,
				`To update infrastructure and facilities.`,
			],
			poes: [
				`PEO1: Provide socially responsible, environment friendly solutions to Electronics and Telecommunication Engineering related broad-based problems adapting professional ethics.`,
				`PEO2: Adapt state-of-the-art Electronics and Telecommunication Engineering broad-based technologies to work in multi-disciplinary work environments.`,
				`PEO3: Solve broad-based problems individually and as a team member communicating effectively in the world of work.`,
			],
			psos: [
				`PSO1: Electronics and Telecommunication Systems: Maintain various types of Electronics and Telecommunication systems.`,
				`PSO2: EDA Tools Usage: Use EDA tools to develop simple Electronics and Telecommunication engineering related circuits`,
			],
			pos: [
				`PO1:Basic and Discipline Specific Knowledge: Apply knowledge of basic mathematics, science and engineering fundamentals and engineering specialization to solve the engineering problems.`,
				`PO2: Problem analysis: Identify and analyse well-defined engineering problems using codified standard methods.`,
				`PO3: Design/ development of solutions: Design solutions for well-defined technical problems and assist with the design of systems components or processes to meet specified needs.`,
				`PO4: Engineering Tools, Experimentation and Testing: Apply modern engineering tools and appropriate technique to conduct standard tests and measurements.`,
				`PO5: Engineering practices for society, sustainability and environment: Apply appropriate technology in context of society, sustainability, environment and ethical practices.`,
				`PO6: Project Management: Use engineering management principles individually, as a team member or a leader to manage projects and effectively communicate about well-defined engineering activities.`,
				`PO7: Life-long learning: Ability to analyses individual needs and engage in updating in the context of technological change.`,
			],
		},
		workshop: {
			description: `The Central Workshop of the Institute is the Central facility which provides training to diploma students and also assists them in the fabrication of their projects and experimental setups. It also provides maintenance services to various departments of Institute in its day to day working. Workshop also includes central store which perform all purchase and write off related activities of dead stock as well as consumable material for various departments of Institute. Workshop Superintendent is the store officer by virtue of post.
			The set-up of the Central Workshop is divided in shops namely Machine Shop, Fitting Shop, Carpentry Shop, Black Smithy Shop, Plumbing and Sheet Metal Shop and Welding Shop. All shops of the central workshop are well equipped for training purpose according to new modern technology. In the era of rapid industrialization particularly in the engineering field, requirements of specialized tools, Lathe, Shaper, Planar, CNC (Computer Numerical Control) Machines & other precision Machines are indispensable. Simultaneously demand for high quality toolmakers are also growing rapidly.
			On behalf of our Staffs members, I welcome you for an exciting learning journey.The Central workshop was started in the year 1983 and has made remarkable achievements during this journey.From students’ corner, workshop plays an important role to develop new technical skills and knowledge in the field of new and emerging machines as well as to nurture tomorrow’s industrial technicians. We inform them, the importance of machines (includes proper tooling) and its usefulness in industry. Our practical exposure to machines covers almost all aspects of today’s industries requirements.										
			Central Workshop of an Institute is considered as the forehand platform providing the real industrial environment for the students. Students are engaged in workshop from their first semester, performing practical's with different materials, tools and machines. Students are exposed to get real-time hands-on practice and experience at various sections like Welding shop, Wood working and pattern making, CNC shop, Machine shop, Turning shop, Black-smithy shop, Sheet metal and Plumbing shop, Moulding shop and fitting shop.Diploma engineers are expected to possess various hands on skills in operation of various tools and machines in Industry. A comprehensive Curriculum of workshop practices is framed by MSBTE (Maharashtra State Board of Technical Education, Mumbai) for all diploma engineering streams so as to inculcate operational and maintenance attitude towards Industrial working culture. Workshop department, along with curricular activities provides a platform to perform in house students work related to micro-project, final year projects, Industrial sponsored projects etc. CNC shop is equipped with state-of-the-art CNC Lathe and 4 Axis robotic arm where students perform various program-based machining operations`,
			vision: `To be a centre of skill Development.`,
			mission: [
				`M1. To Provide quality Education , training, and innovative engineering solutions.`,
				`
			M2. To Provide platform to the learner towards attaining skills of manufacturing sector.`,
				`
			M3. To undertake skill enhancement training programmes for students.`,
				`
			M4. To impart Social and professional ethics.`,
			],
			poes: [
				`PEO1: Produce quality and skilled manpower through practical training`,
				`PEO2: To establish a Skill development centre in manufacturing Sector`,
				`PEO3: To establish a production centre of utility articles for institute`,
				`PEO4: To facilitate other Faculty / Department to play their rightful role in line with the mission and vision of the institute`,
				`PEO5: To generate internal Revenue to supplement government funding to the Institute`,
			],
			psos: [
				`PSO1: Electronics and Telecommunication Systems: Maintain various types of Electronics and Telecommunication systems.`,
				`PSO2: EDA Tools Usage: Use EDA tools to develop simple Electronics and Telecommunication engineering related circuits`,
			],
			pos: [
				`PO1:Basic and Discipline Specific Knowledge: Apply knowledge of basic mathematics, science and engineering fundamentals and engineering specialization to solve the engineering problems.`,
				`PO2: Problem analysis: Identify and analyse well-defined engineering problems using codified standard methods.`,
				`PO3: Design/ development of solutions: Design solutions for well-defined technical problems and assist with the design of systems components or processes to meet specified needs.`,
				`PO4: Engineering Tools, Experimentation and Testing: Apply modern engineering tools and appropriate technique to conduct standard tests and measurements.`,
				`PO5: Engineering practices for society, sustainability and environment: Apply appropriate technology in context of society, sustainability, environment and ethical practices.`,
				`PO6: Project Management: Use engineering management principles individually, as a team member or a leader to manage projects and effectively communicate about well-defined engineering activities.`,
				`PO7: Life-long learning: Ability to analyses individual needs and engage in updating in the context of technological change.`,
			],
		},
		applied_mechanics: {
			description: `Welcome to the Department of Applied Mechanics, where we bridge the gap between theoretical
			 principles and real-world engineering challenges. Our department focuses on equipping students with the 
			 skills and knowledge required to understand and solve mechanical, fluid, and structural problems in various engineering fields.
We offer a blend of classroom learning and hands-on experiences through state-of-the-art laboratories and research projects, preparing our graduates for diverse careers in engineering industries.`,
			vision: `To be a leading department in the application of mechanics principles to create innovative solutions for the modern engineering world.`,
			mission: [
				`Provide students with a strong foundation in mechanics and engineering principles.`,
				`
			Foster research and development in applied mechanics to tackle real-world challenges.`,
				`
			Equip students with the tools and expertise needed for professional excellence in mechanical and civil engineering industries.`,
				
			],
			poes: [
				`PEO1: Produce quality and skilled manpower through practical training`,
				`PEO2: To establish a Skill development centre in manufacturing Sector`,
				`PEO3: To establish a production centre of utility articles for institute`,
				`PEO4: To facilitate other Faculty / Department to play their rightful role in line with the mission and vision of the institute`,
				`PEO5: To generate internal Revenue to supplement government funding to the Institute`,
			],
			psos: [
				`PSO1: Electronics and Telecommunication Systems: Maintain various types of Electronics and Telecommunication systems.`,
				`PSO2: EDA Tools Usage: Use EDA tools to develop simple Electronics and Telecommunication engineering related circuits`,
			],
			pos: [
				`PO1:Basic and Discipline Specific Knowledge: Apply knowledge of basic mathematics, science and engineering fundamentals and engineering specialization to solve the engineering problems.`,
				`PO2: Problem analysis: Identify and analyse well-defined engineering problems using codified standard methods.`,
				`PO3: Design/ development of solutions: Design solutions for well-defined technical problems and assist with the design of systems components or processes to meet specified needs.`,
				`PO4: Engineering Tools, Experimentation and Testing: Apply modern engineering tools and appropriate technique to conduct standard tests and measurements.`,
				`PO5: Engineering practices for society, sustainability and environment: Apply appropriate technology in context of society, sustainability, environment and ethical practices.`,
				`PO6: Project Management: Use engineering management principles individually, as a team member or a leader to manage projects and effectively communicate about well-defined engineering activities.`,
				`PO7: Life-long learning: Ability to analyses individual needs and engage in updating in the context of technological change.`,
			],
		},
		science_humanities: {
			description: `The Department of Science and Humanities is a cornerstone of our institution, offering foundational 
			courses that support the technical and professional development of students across all engineering disciplines. 
			The department provides students with the essential knowledge in subjects like mathematics, physics, chemistry, 
			English, and social sciences, forming the base upon which their technical skills are built.
Our aim is to produce well-rounded individuals who are not only experts in their respective engineering fields but also equipped with critical thinking, problem-solving, and communication skills essential for personal and professional success.`,
			vision: `To be a leading department in fostering academic excellence and holistic development in students by imparting strong scientific knowledge and enhancing their understanding of the humanities.`,
			mission: [
				`Provide a strong foundation in core scientific disciplines and humanities.`,
				`
			Encourage interdisciplinary learning that nurtures curiosity, creativity, and critical thinking..`,
				`
			Equip students with the tools to excel in technical and social dimensions of their careers.`,
				
			],
			poes: [
				`PEO1: Produce quality and skilled manpower through practical training`,
				`PEO2: To establish a Skill development centre in manufacturing Sector`,
				`PEO3: To establish a production centre of utility articles for institute`,
				`PEO4: To facilitate other Faculty / Department to play their rightful role in line with the mission and vision of the institute`,
				`PEO5: To generate internal Revenue to supplement government funding to the Institute`,
			],
			psos: [
				`PSO1: Electronics and Telecommunication Systems: Maintain various types of Electronics and Telecommunication systems.`,
				`PSO2: EDA Tools Usage: Use EDA tools to develop simple Electronics and Telecommunication engineering related circuits`,
			],
			pos: [
				`PO1:Basic and Discipline Specific Knowledge: Apply knowledge of basic mathematics, science and engineering fundamentals and engineering specialization to solve the engineering problems.`,
				`PO2: Problem analysis: Identify and analyse well-defined engineering problems using codified standard methods.`,
				`PO3: Design/ development of solutions: Design solutions for well-defined technical problems and assist with the design of systems components or processes to meet specified needs.`,
				`PO4: Engineering Tools, Experimentation and Testing: Apply modern engineering tools and appropriate technique to conduct standard tests and measurements.`,
				`PO5: Engineering practices for society, sustainability and environment: Apply appropriate technology in context of society, sustainability, environment and ethical practices.`,
				`PO6: Project Management: Use engineering management principles individually, as a team member or a leader to manage projects and effectively communicate about well-defined engineering activities.`,
				`PO7: Life-long learning: Ability to analyses individual needs and engage in updating in the context of technological change.`,
			],
		},
	};
	return (
		<>
			<div className="about-department-container text-justify p-2">
				<h1>
					 <span className="heading-red"> From HOD's Desk</span>
				</h1>
				{branch === "computer" && DeptAboutData.computer.description}
				{branch === "civil" && DeptAboutData.civil.description}
				{branch === "mechanical" &&
					DeptAboutData.mechanical.description}
				{branch === "electrical" &&
					DeptAboutData.electrical.description}
				{branch === "electronics" &&
					DeptAboutData.electronics.description}
				{branch === "workshop" && DeptAboutData.workshop.description}
				{branch === "applied_mechanics" && DeptAboutData.applied_mechanics.description}
				{branch === "science_humanities" && DeptAboutData.science_humanities.description}
			</div>
			{branch === "computer" && (
				<Vision_Mission
					vision={DeptAboutData.computer.vision}
					mission={DeptAboutData.computer.mission}
				/>
			)}
			{branch === "civil" && (
				<Vision_Mission
					vision={DeptAboutData.civil.vision}
					mission={DeptAboutData.civil.mission}
				/>
			)}
			{branch === "mechanical" && (
				<Vision_Mission
					vision={DeptAboutData.mechanical.vision}
					mission={DeptAboutData.mechanical.mission}
				/>
			)}
			{branch === "electrical" && (
				<Vision_Mission
					vision={DeptAboutData.electrical.vision}
					mission={DeptAboutData.electrical.mission}
				/>
			)}
			{branch === "electronics" && (
				<Vision_Mission
					vision={DeptAboutData.electronics.vision}
					mission={DeptAboutData.electronics.mission}
				/>
			)}
			{branch === "workshop" && (
				<Vision_Mission
					vision={DeptAboutData.workshop.vision}
					mission={DeptAboutData.workshop.mission}
				/>
			)}
			{branch === "applied_mechanics" && (
				<Vision_Mission
					vision={DeptAboutData.applied_mechanics.vision}
					mission={DeptAboutData.applied_mechanics.mission}
				/>
			)}
			{branch === "science_humanities" && (
				<Vision_Mission
					vision={DeptAboutData.science_humanities.vision}
					mission={DeptAboutData.science_humanities.mission}
				/>
			)}
			<div className="dept-peos-block my-16">
				<h1 className="heading-top-left-line">PEOs</h1>
				<ul>
					{branch === "computer" &&
						DeptAboutData.computer.poes.map((poe) => (
							<li className="list-disc">{poe}</li>
						))}
					{branch === "civil" &&
						DeptAboutData.civil.poes.map((poe) => (
							<li className="list-disc">{poe}</li>
						))}
					{branch === "mechanical" &&
						DeptAboutData.mechanical.poes.map((poe) => (
							<li className="list-disc">{poe}</li>
						))}
					{branch === "electrical" &&
						DeptAboutData.electrical.poes.map((poe) => (
							<li className="list-disc">{poe}</li>
						))}
					{branch === "electronics" &&
						DeptAboutData.electronics.poes.map((poe) => (
							<li className="list-disc">{poe}</li>
						))}
					{branch === "workshop" &&
						DeptAboutData.workshop.poes.map((poe) => (
							<li className="list-disc">{poe}</li>
						))}
						{branch === "applied_mechanics" &&
						DeptAboutData.applied_mechanics.poes.map((poe) => (
							<li className="list-disc">{poe}</li>
						))}
						{branch === "science_humanities" &&
						DeptAboutData.science_humanities.poes.map((poe) => (
							<li className="list-disc">{poe}</li>
						))}
				</ul>
			</div>
			<div className="dept-psos-pos-block grid lg:grid-cols-2 gap-8">
				<div className="psos-block">
					<h1 className="heading-top-left-line">PSOs</h1>
					<ul className="text-justify">
						{branch === "computer" &&
							DeptAboutData.computer.psos.map((pso) => (
								<li className="list-disc">{pso}</li>
							))}
						{branch === "civil" &&
							DeptAboutData.civil.psos.map((pso) => (
								<li className="list-disc">{pso}</li>
							))}
						{branch === "mechanical" &&
							DeptAboutData.mechanical.psos.map((pso) => (
								<li className="list-disc">{pso}</li>
							))}
						{branch === "electrical" &&
							DeptAboutData.electrical.psos.map((pso) => (
								<li className="list-disc">{pso}</li>
							))}
						{branch === "electronics" &&
							DeptAboutData.electronics.psos.map((pso) => (
								<li className="list-disc">{pso}</li>
							))}
						{branch === "workshop" &&
							DeptAboutData.workshop.psos.map((pso) => (
								<li className="list-disc">{pso}</li>
							))}
							{branch === "applied_mechanics" &&
							DeptAboutData.applied_mechanics.psos.map((pso) => (
								<li className="list-disc">{pso}</li>
							))}
							{branch === "science_humanities" &&
							DeptAboutData.science_humanities.psos.map((pso) => (
								<li className="list-disc">{pso}</li>
							))}
					</ul>
				</div>
				<div className="pos-block">
					<h1>POs</h1>
					<ul className="text-justify">
						{branch === "computer" &&
							DeptAboutData.computer.pos.map((po) => (
								<li className="list-disc">{po}</li>
							))}
						{branch === "civil" &&
							DeptAboutData.civil.pos.map((po) => (
								<li className="list-disc">{po}</li>
							))}
						{branch === "mechanical" &&
							DeptAboutData.mechanical.pos.map((po) => (
								<li className="list-disc">{po}</li>
							))}
						{branch === "electrical" &&
							DeptAboutData.electrical.pos.map((po) => (
								<li className="list-disc">{po}</li>
							))}
						{branch === "electronics" &&
							DeptAboutData.electronics.pos.map((po) => (
								<li className="list-disc">{po}</li>
							))}
						{branch === "workshop" &&
							DeptAboutData.workshop.pos.map((po) => (
								<li className="list-disc">{po}</li>
							))}
							{branch === "applied_mechanics" &&
							DeptAboutData.applied_mechanics.pos.map((po) => (
								<li className="list-disc">{po}</li>
							))}
							{branch === "science_humanities" &&
							DeptAboutData.science_humanities.pos.map((po) => (
								<li className="list-disc">{po}</li>
							))}
					</ul>
				</div>
			</div>
		</>
	);
}
