import React from "react";
import Internet from "../../images/Internet.jpg";

export default function () {
	return (
	<>
			<h1 className="heading heading-top-left-line">Internet</h1>
      <hr className="my-6" />
			<div classname="maindiv1" >
		<div>
			<img style={{ width: 800, height: 500 }} src={Internet} alt="" />
		</div>
<br></br>


        <p classname="p1 ">
     <u>  <ul>   <b> Additional Information:</b></ul></u> 
       <br></br>
       <b> Server Room Capacity:</b> 8 racks
       <br></br>
       <b> Environmental Controls:</b> Temperature and humidity monitoring and control
       <br></br>
       <b> Backup and Disaster Recovery Plan:</b> Daily backups and a disaster recovery plan in place
       <br></br>
       <b> Network Connectivity:</b> 1Gbps internet connection and local network connectivity

</p>

		</div>

	</>
	);
}