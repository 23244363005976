import React, { useState } from "react";
import { FiPlus, FiMinus } from "react-icons/fi";
import { useParams } from "react-router-dom";

export default function DeptNewsLetter() {
  const { branch } = useParams();
  const [openIndex, setOpenIndex] = useState(null);

  const togglePDF = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  // 📌 Different Newsletters for each branch
  const newsLetterData = {
    computer: [
      { name: "2022-2023", url: "https://gpsakoli.ac.in/news-letters/computer/nlct23.pdf" },
      { name: "2021-2022", url: "https://gpsakoli.ac.in/news-letters/computer/nlct22.pdf" },
      { name: "2020-2021", url: "https://gpsakoli.ac.in/news-letters/computer/nlct21.pdf" },
    ],
    civil: [
      { name: "2022-2023", url: "https://gpsakoli.ac.in/news-letters/civil/nlc23.pdf" },
      { name: "2021-2022", url: "https://gpsakoli.ac.in/news-letters/civil/nlc22.pdf" },
      { name: "2020-2021", url: "https://gpsakoli.ac.in/news-letters/civil/nlc21.pdf" },
    ],
    mechanical: [
      { name: "2022-2023", url: "https://gpsakoli.ac.in/news-letters/mechanical/nlm23.pdf" },
      { name: "2021-2022", url: "https://gpsakoli.ac.in/news-letters/mechanical/nlm22.pdf" },
      { name: "2020-2021", url: "https://gpsakoli.ac.in/news-letters/mechanical/nlm21.pdf" },
    ],
    electrical: [
      { name: "2022-2023", url: "https://gpsakoli.ac.in/news-letters/electrical/nle23.pdf" },
      { name: "2021-2022", url: "https://gpsakoli.ac.in/news-letters/electrical/nle22.pdf" },
      { name: "2020-2021", url: "https://gpsakoli.ac.in/news-letters/electrical/nle21.pdf" },
    ],
    electronics: [
      { name: "2022-2023", url: "https://gpsakoli.ac.in/news-letters/electronics/nlee23.pdf" },
      { name: "2023-2024", url: "https://gpsakoli.ac.in/public/api/assets/NewsLetters_PDFs/electronics/News%20Letter%20%202023-24.pdf" },
      { name: "2020-2021", url: "https://gpsakoli.ac.in/public/api/assets/NewsLetters_PDFs/electronics/News%20Letter%20_%20ODD%20Sem%202021.docx.pdf" },
    ],
  };

  const branchNewsLetters = newsLetterData[branch];

  return (
    <div >
      <h1 className="heading heading-top-left-line">News Letter </h1>

      {branchNewsLetters ? (
        // 📌 Display two newsletters per row using a simple block layout
        <div className="newsletters">
          {branchNewsLetters.map((item, index) => (
            <div key={index} className="newsletter-item mb-4">
              <div
                className="p-4 shadow-md"
                style={{
                  background: "linear-gradient(160deg, var(--primary-bg-red), var(--card-linear-dark-red))",
                }}
              >
                <button
                  onClick={() => togglePDF(index)}
                  className="w-full flex justify-between items-center text-lg font-semibold text-white hover:underline focus:outline-none"
                >
                  <span>{item.name}</span>
                  {openIndex === index ? <FiMinus size={20} /> : <FiPlus size={20} />}
                </button>

                {openIndex === index && (
                  <div className="mt-3 border rounded-lg overflow-hidden">
                    <iframe
                      src={item.url}
                      width="100%"
                      height="500px"
                      className="border-none"
                      title={item.name}
                    ></iframe>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p><p className="text-red-500 text-2xl font-bold text-center mt-4">
        No NewsLetter data available for this branch !!
      </p></p>
      )}
    </div>
  );
}
