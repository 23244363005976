


import React, { useState } from "react";
import { FiPlus, FiMinus } from "react-icons/fi";
import { useParams } from "react-router-dom";

export default function DeptCurriculum() {
  const { branch } = useParams();
  const [openIndex, setOpenIndex] = useState(null);

  const togglePDF = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  // 📌 Different Curriculums for each branch
  const CurriculumData = {
    computer: [
      { name: " Semester 1", url: "https://gpsakoli.ac.in/public/api/assets/MOUs_PDFs/computer/AI_(truexams.com).pdf" },
      { name: " Semester 2", url: "https://gpsakoli.ac.in/public/api/assets/MOUs_PDFs/computer/ilovepdf_merged.pdf" },
      { name: " Semester 3", url: "https://gpsakoli.ac.in/public/api/assets/MOUs_PDFs/computer/CM3K_Full_(truexams.com).pdf" },
      { name: " Semester 4", url: "https://gpsakoli.ac.in/public/api/assets/MOUs_PDFs/computer/ilovepdf_merged%20(1).pdf" },
      { name: " Semester 5", url: "https://gpsakoli.ac.in/public/api/assets/MOUs_PDFs/computer/sem-5th-computer-engg-i-scheme-syllabus.pdf" },
      { name: " Semester 6", url: "https://gpsakoli.ac.in/public/api/assets/MOUs_PDFs/computer/sem-6th-computer-engg-i-scheme-syllabus.pdf" },
      
    ],
    civil: [
      { name: " Semester 1", url: "https://gpsakoli.ac.in/public/api/assets/MOUs_PDFs/civil/AA_(truexams.com).pdf" },
      { name: " Semester 2", url: "https://gpsakoli.ac.in/public/api/assets/MOUs_PDFs/civil/CE_All_(truexams.com).pdf" },
      { name: " Semester 3", url: "https://gpsakoli.ac.in/public/api/assets/MOUs_PDFs/civil/CE3K_All_(truexams.com).pdf" },
      { name: " Semester 4", url: "https://gpsakoli.ac.in/public/api/assets/MOUs_PDFs/civil/ilovepdf_merged.pdf" },
      { name: " Semester 5", url: "https://gpsakoli.ac.in/public/api/assets/MOUs_PDFs/civil/sem-5th-civil-engg-i-scheme-syllabus.pdf" },
      { name: " Semester 6", url: "https://gpsakoli.ac.in/public/api/assets/MOUs_PDFs/civil/sem-6th-civil-engg-i-scheme-syllabus.pdf" },
    ],
    mechanical: [
      { name: " Semester 1", url: "https://gpsakoli.ac.in/public/api/assets/MOUs_PDFs/mechanical/AE_(truexams.com).pdf" },
      { name: " Semester 2", url: "https://gpsakoli.ac.in/public/api/assets/MOUs_PDFs/mechanical/ME_AE_MK-2K%20(1)_merged.pdf" },
      { name: " Semester 3", url: "https://gpsakoli.ac.in/public/api/assets/MOUs_PDFs/mechanical/ME3K_All_(truexams.com).pdf" },
      { name: " Semester 4", url: "https://gpsakoli.ac.in/public/api/assets/MOUs_PDFs/mechanical/ilovepdf_merged.pdf" },
      { name: " Semester 5", url: "https://gpsakoli.ac.in/public/api/assets/MOUs_PDFs/mechanical/sem-5th-mechanical-engg-i-scheme-syllabus.pdf" },
      { name: " Semester 6", url: "https://gpsakoli.ac.in/public/api/assets/MOUs_PDFs/mechanical/sem-6th-mechanical-engg-i-scheme-syllabus.pdf" },
    ],
    electrical: [
      { name: " Semester 1", url: "https://gpsakoli.ac.in/public/api/assets/MOUs_PDFs/electrical/ee%20first%20sem_merged.pdf" },
      { name: " Semester 2", url: "https://gpsakoli.ac.in/public/api/assets/MOUs_PDFs/electrical/EE%20second%20sem_merged.pdf" },
      { name: " Semester 3", url: "https://gpsakoli.ac.in/public/api/assets/MOUs_PDFs/electrical/EE3K_Full_(truexams.com).pdf" },
      { name: " Semester 4", url: "https://gpsakoli.ac.in/public/api/assets/MOUs_PDFs/electrical/ee%20forth%20sem_merged.pdf" },
      { name: " Semester 5", url: "https://gpsakoli.ac.in/public/api/assets/MOUs_PDFs/electrical/ee%20fifth%20sem_merged.pdf" },
      { name: " Semester 6", url: "https://gpsakoli.ac.in/public/api/assets/MOUs_PDFs/electronics/sem-6th-electronics-engg-i-scheme-syllabus.pdf" },
    ],
    electronics: [
      { name: " Semester 1", url: "https://gpsakoli.ac.in/public/api/assets/MOUs_PDFs/electronics/AO_Full-(truexams.com).pdf" },
      { name: " Semester 2", url: "https://gpsakoli.ac.in/public/api/assets/MOUs_PDFs/electronics/ilovepdf_merged.pdf" },
      { name: " Semester 3", url: "https://gpsakoli.ac.in/public/api/assets/MOUs_PDFs/electronics/EJ3K_All_(truexams.com).pdf" },
      { name: " Semester 4", url: "https://gpsakoli.ac.in/public/api/assets/MOUs_PDFs/electronics/EJ%204K%20Syllabus_merged.pdf" },
      { name: " Semester 5", url: "https://gpsakoli.ac.in/public/api/assets/MOUs_PDFs/electronics/sem-5th-electronics-engg-i-scheme-syllabus.pdf" },
      { name: " Semester 6", url: "https://gpsakoli.ac.in/public/api/assets/MOUs_PDFs/electronics/sem-6th-electronics-engg-i-scheme-syllabus.pdf" },
    ],




  };

  const branchCurriculum = CurriculumData[branch];

  return (
    <div >
             <h1 className="heading heading-top-left-line">Curriculum</h1>  <br></br>


      {branchCurriculum ? (
        // 📌 Display two semesters per row
        [0, 2, 4].map((startIndex) => (
          <div key={startIndex} className="flex flex-wrap -mx-2 mb-4">
            {[0, 1].map((offset) => {
              const index = startIndex + offset;
              if (!branchCurriculum[index]) return null;
              return (
                <div key={index} className="w-full md:w-1/2 px-2">
                  <div
                    className="p-4 shadow-md"
                    style={{
                      background: "linear-gradient(160deg, var(--primary-bg-red), var(--card-linear-dark-red))",
                    }}
                  >
                    <button
                      onClick={() => togglePDF(index)}
                      className="w-full flex justify-between items-center text-lg font-semibold text-white hover:underline focus:outline-none"
                    >
                      <span>{branchCurriculum[index].name}</span>
                      {openIndex === index ? <FiMinus size={20} /> : <FiPlus size={20} />}
                    </button>

                    {openIndex === index && (
                      <div className="mt-3 border rounded-lg overflow-hidden">
                        <iframe
                          src={branchCurriculum[index].url}
                          width="100%"
                          height="500px"
                          className="border-none"
                          title={branchCurriculum[index].name}
                        ></iframe>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        ))
      ) : (
        <p><p className="text-red-500 text-2xl font-bold text-center mt-4">
        No Curriculum data available for this branch !!
      </p></p>
      )}
    </div>
  );
}

