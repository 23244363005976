import React from "react";
import img from "../../images/supporting-staff-images/computer/dhabale.jpeg";
export default function OfficeStaff() {
	return (
		<>
			<h1 className="heading heading-top-left-line"> Office Staff</h1>
			<table border="1px">
				<thead>
					<tr>
						<td>Sr. No.</td>
						<td>Photo</td>
						<td>Name Of Working Staff</td>
						<td>Mobile Number</td>
						<td>Designation</td>
						<td>Email ID</td>
						
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>1</td>
						<td>
							
						{/*<img src={img} width={100} height={100} alt="" />*/}
						</td>
						<td>
							Ganesh Patle
						</td>
						<td>9881988198</td>
						<td>
							Office Superident
						</td>
						<td>
							patleganesh1977@gmail.com
						</td>
					</tr>
					<tr>
						<td>2</td>
						<td>
						{/*<img src={img} width={100} height={100} alt="" />*/}
						</td>
						<td>
							Pankaj Patil 
					
						</td>
						<td>880794143</td>
						<td>
							Stenographer
						</td>
						<td>
							pp442401@gmail.com
						</td>
					</tr>
					<tr>
						<td>3</td>
						<td>
						{/*<img src={img} width={100} height={100} alt="" />*/}
						</td>
						<td>
							Archana Ramavat
						</td>
						<td>8788054708</td>
						<td>
							Head Clerk
						</td>
						<td>
							apujari73@gmail.com
						</td>
					</tr>
					<tr>
						<td>4</td>
						<td>
						{/*<img src={img} width={100} height={100} alt="" />*/}
						</td>
						<td>
							Vinod Nakhale
						</td>
						<td>9975446923</td>
						<td>
							Head Clerk
						</td>
						<td>
							vvnakhale@gmail.com
						</td>
					</tr>
					<tr>
						<td>5</td>
						<td>
						{/*<img src={img} width={100} height={100} alt="" />*/}
						</td>
						<td>
							Kewalram Wadwale
						</td>
						<td>9158395911</td>
						<td>
							Head Clerk
						</td>
						<td>
							knwadwale@rediffmail.com
						</td>
					</tr>
						<tr>
						<td>6</td>
						<td>
						{/*<img src={img} width={100} height={100} alt="" />*/}
						</td>
						<td>
							Sachin Gedam
						</td>
						<td>9921726231</td>
						<td>
							Data Entry Opreator
						</td>
						<td>
							sachingedam13@gmail.com
						</td>
					</tr>
					<tr>
						<td>7</td>
						<td>
						{/*<img src={img} width={100} height={100} alt="" />*/}
						</td>
						<td>
							Vitthal Ingale
						</td>
						<td>9764043032</td>
						<td>
							Store Keeper
						</td>
						<td>
							vitthalingale1@gmail.com
						</td>
					</tr>
					<tr>
						<td>8</td>
						<td>
						{/*<img src={img} width={100} height={100} alt="" />*/}
						</td>
						<td>
							Punit Barmase
						</td>
						<td>7888229860</td>
						<td>
							Senior Clerk
						</td>
						<td>
							punitbarmase88@gmail.com
						</td>
					</tr>
					<tr>
						<td>9</td>
						<td>
						{/*<img src={img} width={100} height={100} alt="" />*/}
						</td>
						<td>
							Rajesh Hukare
						</td>
						<td>9423641521</td>
						<td>
							Senior Clerk
						</td>
						<td>
							rhhukare@gmail.com
						</td>
					</tr>
					<tr>
						<td>10</td>
						<td>
						{/*<img src={img} width={100} height={100} alt="" />*/}
						</td>
						<td>
							Vinit Meshram
						</td>
						<td>99752855</td>
						<td>
							Senior Clerk
						</td>
						<td>
							vinitmeshram@gmail.com
						</td>
					</tr>
					<tr>
						<td>10</td>
						<td>
						{/*<img src={img} width={100} height={100} alt="" />*/}
						</td>
						<td>
							Nishant Mishra 
						</td>
						<td>8459273218</td>
						<td>
							Senior Clerk
						</td>
						<td>
							prabhadee1986@gmail.com
						</td>
					</tr>
					<tr>
						<td>12</td>
						<td>
						{/*<img src={img} width={100} height={100} alt="" />*/}
						</td>
						<td>
							Laxman Chopkar
						</td>
						<td>9923529422</td>
						<td>
							Junior Clerk
						</td>
						<td>
							laxamnchopkar@gmail.com
						</td>
					</tr>
					<tr>
						<td>13</td>
						<td>
						{/*<img src={img} width={100} height={100} alt="" />*/}
						</td>
						<td>
							Suresh Tandekar
						</td>
						<td>9403880105</td>
						<td>
							Junior Clerk
						</td>
						<td>
							tandekar.suresh@gmail.com
						</td>
					</tr>
					<tr>
						<td>14</td>
						<td>
						{/*<img src={img} width={100} height={100} alt="" />*/}
						</td>
						<td>
							Madhukar Rathod
						</td>
						<td>8999711315</td>
						<td>
							Junior Clerk
						</td>
						<td>
							madhukarrathod81@gmail.com
						</td>
					</tr>
					<tr>
						<td>15</td>
						<td>
						{/*<img src={img} width={100} height={100} alt="" />*/}
						</td>
						<td>
							Krushana Gedam
						</td>
						<td>9421636185</td>
						<td>
							Instructor Curator
						</td>
						<td>
							krishnagedam855@gmail.com
						</td>
					</tr>
					<tr>
						<td>16</td>
						<td>
						{/*<img src={img} width={100} height={100} alt="" />*/}
						</td>
						<td>
							Naresh Tembhurne
						</td>
						<td>9673349648</td>
						<td>
							Instructor Instrument Mechanic
						</td>
						<td>
							ntembhurnee@gmail.com
						</td>
					</tr>
					<tr>
						<td>17</td>
						<td>
						{/*<img src={img} width={100} height={100} alt="" />*/}
						</td>
						<td>
							Rajesh Pisode
						</td>
						<td>9271628086</td>
						<td>
							Instructor ELectrician 
						</td>
						<td>
							rajeshpisode23@gmail.com
						</td>
					</tr>
					<tr>
						<td>18</td>
						<td>
						{/*<img src={img} width={100} height={100} alt="" />*/}
						</td>
						<td>
							Ajay Dhane
						</td>
						<td>9604585772</td>
						<td>
							Instructor Turner
						</td>
						<td>
							ajaydahane91@gmail.com
						</td>
					</tr>
					<tr>
						<td>19</td>
						<td>
						{/*<img src={img} width={100} height={100} alt="" />*/}
						</td>
						<td>
							Vinod Dhote
						</td>
						<td>9403416815</td>
						<td>
							Instructor General Mechanics
						</td>
						<td>
							vinoddhote59@gmail.com
						</td>
					</tr>
					<tr>
						<td>20</td>
						<td>
						{/*<img src={img} width={100} height={100} alt="" />*/}
						</td>
						<td>
							Nilima Shende
						</td>
						<td>9511643476</td>
						<td>
							Instructor Labrotory Assistant
						</td>
						<td>
							nilimashende817@gmail.com
						</td>
					</tr>
					<tr>
						<td>21</td>
						<td>
						{/*<img src={img} width={100} height={100} alt="" />*/}
						</td>
						<td>
							Abhijit Khairnar
						</td>
						<td>9373789169</td>
						<td>
							Instructor Labrotory Assistant
						</td>
						<td>
							ashish.gpn@gov.in
						</td>
					</tr>
					<tr>
						<td>22</td>
						<td>
						{/*<img src={img} width={100} height={100} alt="" />*/}
						</td>
						<td>
							Kolba Chavhan
						</td>
						<td>982231725</td>
						<td>
							Labrotory Assistant
						</td>
						<td>
							Kolbaji@gmail.com
						</td>
					</tr>
					<tr>
						<td>23</td>
						<td>
						{/*<img src={img} width={100} height={100} alt="" />*/}
						</td>
						<td>
							Sheshrao Kaurati
						</td>
						<td>9423100927</td>
						<td>
							Labrotory Assistant
						</td>
						<td>
							Kaurati@gmail.com
						</td>
					</tr>
					<tr>
						<td>24</td>
						<td>
						{/*<img src={img} width={100} height={100} alt="" />*/}
						</td>
						<td>
							Ananta Khurge
						</td>
						<td>9423674951</td>
						<td>
							Labrotory Assistant
						</td>
						<td>
							Akhurnge@gmail.com
						</td>
					</tr>
					<tr>
						<td>25</td>
						<td>
						{/*<img src={img} width={100} height={100} alt="" />*/}
						</td>
						<td>
							Rupali Aswale
						</td>
						<td>9623441321</td>
						<td>
							Labrotory Assistant
						</td>
						<td>
							rupaliberade@gmail.com
						</td>
					</tr>
				</tbody>
			</table>
		</>
		
	);
}